<template>
  <v-dialog v-model="modelHelper" persistent max-width="700">
    <edit-content
        v-model="video.menu_title"
        :content-text="video.text"
        @close="$emit('input', false)"
        @save="save()"
        :disabled="!video.content.name">
      <v-card>
        <v-card-title>Ausgewähltes Video
          <v-spacer/>
          <v-btn color="primary" @click="openMediaPool = true">
            Video auswählen
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <video-content v-if="videoSource" :video="video" :controls="true" :scale-to100-width="true"
                             :disable-autoplay="true"/>
              <div v-else>Bitte wähle ein Video aus</div>
            </v-col>
            <v-col v-if="videoSource">
              <v-row>
                <v-col cols="12">
                  <div class="title">{{ video.content.name }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model.number="video.content.max_width" type="number" label="Maximale Breite in Pixel"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model.number="video.content.max_height" type="number" label="Maximale Höhe in Pixel"></v-text-field>
                </v-col>
                <v-col cols="12" v-if="video.content.name">
                  <v-switch v-model="video.content.autoplay" color="primary" label="Autoplay"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle v-if="videoSource">
          Ohne Angabe wird das Video automatisch skaliert
        </v-card-subtitle>

      </v-card>

      <v-dialog v-model="openMediaPool">
        <v-card>
          <v-card-title>Bitte wähle ein Video</v-card-title>
          <mediapool :only-to-choose="true" mime-type="video" @choose="setVideo"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </edit-content>
  </v-dialog>

</template>

<script>
import EditContent from "../component/EditContent";
import Mediapool from "../../media/Mediapool";
import VideoContent from "../contents/VideoContent";

export default {
  components: {VideoContent, Mediapool, EditContent},
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      video: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          autoplay: false,
          max_width: 0,
          max_height: 0,
          name: null,
        },
        order: null,
        key: null,
      },
      openMediaPool: false
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    /**
     * get full path of video file
     * @returns {string|null}
     */
    videoSource() {
      if (this.video?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/videos/${this.video.content.name}`
      }
      return null
    }
  },
  methods: {
    setVideo(video) {
      this.openMediaPool = false
      this.video.content.path = video.path
      this.video.content.name = video.name
    },
    save() {
      this.video.content.max_height = this.video.content.max_height !== "" ? this.video.content.max_height : null
      this.video.content.max_width = this.video.content.max_width !== "" ? this.video.content.max_width : null
      this.$emit('save', this.video)
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.video = JSON.parse(JSON.stringify(this.content))
        this.video.css_id = this.video.css_id === null ? "video-" + Date.now() : this.video.css_id;
      }
    }
  },
}
</script>

<style scoped>

</style>