<template>
  <div>
    <v-btn-toggle
        v-model="mode"
        mandatory>
      <v-btn value="source-over">
        <v-icon :style="{color: (color !== undefined && color !== null) ? color : $vuetify.theme.themes.light.primary}"
                class="me-1">brush
        </v-icon>
        Zeichnen
      </v-btn>
      <v-btn value="destination-out">
        <v-icon :style="{color: (color !== undefined && color !== null) ? color : $vuetify.theme.themes.light.primary}"
                class="me-1">brightness_1
        </v-icon>
        Radieren
      </v-btn>
    </v-btn-toggle>

    <div>
      <div ref="div" class="relative border mt-1"
           :style="{'max-width': this.content.max_width !== null ? this.content.max_width + 10 + 'px' : 'unset'}"
           v-touch="{
                 start: start,
                 down: end,
                 move: drawing}">
        <img class="drawing"
             v-if="content.image_path"
             :width=configKonva.width
             :style="`max-height: ${configKonva.height + 'px'};`"
             :src="content.image_path"/>
        <v-stage ref="stage" :config="configKonva">
          <v-layer ref="layer" @mousedown="start" @mouseup="end"
                   @mousemove="drawing">
            <v-rect :config="configRect"></v-rect>
          </v-layer>
        </v-stage>
      </div>
    </div>
  </div>
</template>

<script>
import Konva from "konva";

export default {
  props: {
    content: {
      required: true
    },
    cardWidth: {
      required: false,
      type: String,
    },
    isEditable: {
      required: false,
      type: Boolean,
      default: true
    },
    color: {
      required: true,
    },
  },
  data() {
    return {
      configKonva: {
        width: 0,
        height: 0
      },
      configRect: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      isPaint: false,
      lastLine: null,
      brush: 'source-over',
      mode: 0,
      image: null
    }
  },
  mounted() {
    /**
     * Update the size when all columns in the parent component are rendered.
     * Otherwise, this component goes over the whole width of the v-card
     */
    this.$nextTick(() => {
      this.updateSize()
      window.addEventListener("resize", this.updateSize)
    })
  },
  methods: {
    /**
     * draw line with start point of mouse / touch
     */
    start() {
      if (!this.isEditable) {
        return
      }
      const stage = this.$refs.stage
      const layer = this.$refs.layer
      this.isPaint = true
      let pos = stage.getNode().getPointerPosition();
      this.lastLine = new Konva.Line({
        stroke: this.content.stroke,
        strokeWidth: this.mode === 'source-over' ? this.content.stroke_width : this.content.stroke_width + 10,
        globalCompositeOperation: this.mode,
        points: [pos.x, pos.y],
      });
      layer.getNode().add(this.lastLine);
    },
    end() {
      this.isPaint = false
    },
    /**
     * concat line while moving with the mouse / touch
     */
    drawing() {
      if (!this.isPaint || !this.isEditable) {
        return;
      }
      const stage = this.$refs.stage
      const pos = stage.getNode().getPointerPosition();
      let newPoints = this.lastLine.points().concat([pos.x, pos.y]);
      this.lastLine.points(newPoints);
    },
    /**
     * adjust width and length
     */
    updateSize() {
      let width = this.$refs.div.clientWidth
      if (this.content.max_width) {
        const isClientWider = this.$refs.div.clientWidth > parseInt(this.content.max_width)
        if (isClientWider) {
          width = parseInt(this.content.max_width)
        }
      }
      const height = this.content.max_height !== null ? parseInt(this.content.max_height) : (this.$refs.div.clientWidth / 2)
      this.configKonva.width = width
      this.configRect.width = width
      this.configKonva.height = height
      this.configRect.height = height
    }
  },
  watch: {
    /**
     * if props are changing, update the size
     */
    content() {
      this.updateSize()
    },
    cardWidth() {
      this.updateSize()
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.updateSize)
  }
}
</script>

<style scoped>

.drawing {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.relative {
  position: relative;
}

.border {
  border: 5px solid black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
</style>