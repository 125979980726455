<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
          v-model="user.street"
          label="Straße"/>
    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4">
      <v-text-field
          v-model="user.postalcode"
          label="PLZ"/>
    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4">
      <v-text-field
          v-model="user.city"
          label="Ort"
      />
    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4">
      <v-text-field
          v-model="user.country"
          label="Land"/>
    </v-col>
    <template>

    </template>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  computed: {
    user: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>