<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700">
    <validation-observer v-slot="{invalid}">
      <form>
        <edit-content
            v-model="divider.menu_title"
            :content-text="divider.text"
            :disabled="invalid"
            @close="$emit('input', false)"
            @save="$emit('save', divider)">
          <validation-provider
              v-slot="{errors}"
              rules="required|between:1,10"
          >
            <v-text-field v-model.number="divider.content.thickness" type="number" label="Stärke"
                          :error-messages="errors"/>
          </validation-provider>
        </edit-content>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import {extend, setInteractionMode, ValidationProvider, ValidationObserver} from "vee-validate";
import {between, required} from "vee-validate/dist/rules";

setInteractionMode('aggressive')

extend('required', {
  ...required,
  message: 'Bitte gebe eine Stärke der Linie ein',
})

extend('between', {
  ...between,
  message: 'Bitte gebe eine gültige Zahl zwischen 1 und 10 ein',
})

export default {
  components: {
    EditContent, ValidationProvider, ValidationObserver
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      divider: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          thickness: null,
        },
        order: null,
        key: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.divider = JSON.parse(JSON.stringify(this.content))
        this.divider.css_id = this.divider.css_id === null ? "divider-" + Date.now() : this.divider.css_id;
      }
    }
  }
}
</script>

<style scoped>

</style>