<template>
    <div>
    <div v-html="nfcContent.content.question"/>
    <v-radio-group v-model="answer">
      <v-row align="center">
        <v-col v-for="(answer, idx) of nfcContent.content.answers"
               :key="`${answer.answer_text}-${idx}`"
               cols="6">
          <v-radio :readonly="readOnly" :label="answer.answer_text" :value="answer.answer_text">
            <template v-slot:label>
              <div class="answers-no-padding" v-html="answer.answer_text"/>
            </template>
          </v-radio>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-row align="center" v-if="!readOnly">
      <v-col cols="auto">
        <v-btn :disabled="answer===null" color="primary" @click="checkAnswer()">Antwort prüfen</v-btn>
      </v-col>
      <v-col v-if="showMessages">
        <div v-if="!isCorrectAnswer" style="color: red">Das war leider die falsche Antwort</div>
        <div v-if="isCorrectAnswer" style="color: green">Richtige Antwort. Sehr gut!</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    nfcContent: {
      required: true
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      answer: null,
      showMessages: false,
      isCorrectAnswer: false,
      correctAnswer: null,
    }
  },
  methods: {
    checkAnswer() {
      this.isCorrectAnswer = this.correctAnswer === this.answer;
      this.showMessages = true
    }
  },
  watch: {
    answer() {
      this.showMessages = false;
    }
  },
  created() {
    const answerObj = this.nfcContent.content.answers.filter(answer => answer.is_correct)
    if (answerObj[0]) {
      this.correctAnswer = answerObj[0].answer_text
    }
  }
}
</script>

<style scoped>
.answers-no-padding >>> p {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-block-end: 0 !important;
}

</style>