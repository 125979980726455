<template>
  <v-dialog v-model="modelHelper" persistent max-width="700">
    <edit-content
        v-model="audio.menu_title"
        :content-text="audio.text"
        @close="$emit('input', false)"
        @save="save()"
        :disabled="!audio.content.name">
      <v-card>
        <v-card-title>Ausgewählte Audiodatei
          <v-spacer/>
          <v-btn color="primary" @click="openMediaPool = true">
            Audiodatei auswählen
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto" v-if="!audio.content.name">
              <div>Bitte wähle eine Audiodatei aus</div>
            </v-col>
            <v-col cols="12" v-if="audio.content.name">
              <div class="title">{{ audio.content.name }}</div>
              <vuetify-audio :file="audioSource" flat/>
            </v-col>
            <v-col cols="12" v-if="audio.content.name">
              <v-switch v-model="audio.content.autoplay" color="primary" label="Autoplay"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog v-model="openMediaPool">
        <v-card>
          <v-card-title>Bitte wähle eine Audiodatei</v-card-title>
          <mediapool :only-to-choose="true" mime-type="audio" @choose="setAudio"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </edit-content>
  </v-dialog>

</template>

<script>
import EditContent from "../component/EditContent";
import Mediapool from "../../media/Mediapool";
import VuetifyAudio from 'vuetify-audio';

export default {
  components: {Mediapool, EditContent, VuetifyAudio},
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      audio: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          autoplay: false,
          name: null,
        },
        order: null,
      },
      openMediaPool: false
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    /**
     * get full path of audio file
     * @returns {string|null}
     */
    audioSource() {
      if (this.content.mime_type === 'audio' && this.content?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/audio/${this.content.content.name}`
      }
      return null
    }
  },
  methods: {
    setAudio(audio) {
      this.audio.content.name = audio.name
      this.openMediaPool = false
    },
    save() {
      this.$emit('save', this.audio)
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.audio = JSON.parse(JSON.stringify(this.content))
        this.audio.css_id = this.audio.css_id === null ? "audio-" + Date.now() : this.audio.css_id;
      }
    }
  },
}
</script>

<style scoped>

</style>