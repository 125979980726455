<template>
  <v-container>
    <user-table/>
  </v-container>
</template>

<script>
  import UserTable from "./components/UserTable";
  export default {
    components: {UserTable}
  }
</script>
