<template>
  <v-hover v-slot="{ hover }" class="flex d-flex flex-column justify-space-between">
    <v-card class="pa-2">
      <v-row no-gutters dense class="max-height-30">
        <v-col cols="auto">
          <v-icon color="primary" class="me-1">{{ content.icon }}</v-icon>
        </v-col>
        <v-col cols="auto" v-if="content.menu_title" class="font-color">Menü Titel: {{ content.menu_title }}</v-col>
      </v-row>

      <!--         MIMETYPE TEXT-->
      <div v-if="text && content.content.text_content">
        <div class="my-2 default-font"
             v-html="content.content.text_content"/>
      </div>
      <!--        MIMETYPE IMAGE-->
      <div v-if="image && content.content.name">
        <img class="image"
             :alt="imageSource"
             :style="`max-height: ${content.content.max_height <= 0 ? 'none' : content.content.max_height + 'px'};
                     max-width:${content.content.max_width <= 0 ? 'none' : content.content.max_width + 'px'};`"
             :src="imageSource"
        />
      </div>
      <!--        MIMETYPE YOUTUBE-->
      <div v-if="youtube && videoId">
        <div class="video-container"
             :style="`padding-bottom: ${content.content.max_height <= 0 ? '56.25%' : content.content.max_height + 'px'};`">
          <iframe v-if="videoId"
                  :style="`max-height: ${content.content.max_height <= 0 ? 'none' : content.content.max_height + 'px'};
                     max-width:${content.content.max_width <= 0 ? 'none' : content.content.max_width + 'px'};`"
                  :src="'https://www.youtube-nocookie.com/embed/' + videoId"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
          </iframe>
          <v-overlay :value="true" absolute :opacity="0"/>
        </div>
      </div>
      <!--        MIMETYPE Link-->
      <div v-if="link && content.content.url">
        <iframe width="100%" height="500"
                :src="content.content.url"
                title="Link" frameborder="0">
        </iframe>
      </div>
      <!--        MIMETYPE CANVAS-->
      <div v-if="canvas">
        <canvas-content :is-editable="false"
                :content="content.content"
                :color="accentColor">
        </canvas-content>
      </div>
      <!--         MIMETYPE QUIZ-->
      <div v-if="quiz && content.content.question">
        <quiz-content :read-only="true" :nfc-content="content"/>
      </div>

      <!--         MIMETYPE RELATIONSHIP-->
      <div v-if="relationship">
        <relationship-content :nfc-content="content" :color="accentColor"/>
      </div>

      <!--        MIMETYPE VIDEO-->
      <div v-if="video && videoSource">
        <video-content :video="content" :disable-autoplay="true"/>
      </div>

      <!--         MIMETYPE EMBED-->
      <div v-if="embed && content.content.text">
        <div class="my-2"
             v-html="content.content.text"/>
      </div>

      <div
          v-if="image && !content.content.name ||
            youtube && !videoId ||
            quiz && !content.content.question ||
            link && !content.content.url ||
            text && !content.content.text_content ||
            video && !videoSource ||
            embed && !content.content.text">
        <div>
          {{ content.text }}
        </div>
      </div>
      <div v-else-if="divider || audio" class="me-4">
        <div>
          {{ content.text }}
        </div>
      </div>

      <!--         MIMETYPE MENU-->
      <div v-if="menu">
        <menu-content :menu="content" :color="accentColor"/>
      </div>

      <!--        MIMETYPE DIVIDER-->
      <div v-if="divider">
        (Stärke: {{ content.content.thickness }})
      </div>

      <!--          MIMETYPE AUDIO-->
      <div v-if="audio">
        (<span v-if="content.content.name">Datei: {{ content.content.name }}, </span>Autoplay:
        {{ content.content.autoplay === 0 || content.content.autoplay === false ? 'aus' : 'an' }})
      </div>

      <template v-if="$vuetify.breakpoint.mobile">
        <v-row align-content="end">
          <v-col cols="auto">
            <v-tooltip bottom v-if="!link">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="$emit('copy')">
                  <v-icon>file_copy</v-icon>
                </v-btn>
              </template>
              <span>Duplizieren</span>
            </v-tooltip>
            <v-spacer v-if="$vuetify.breakpoint.xs"/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="$emit('edit')">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>Bearbeiten</span>
            </v-tooltip>
            <v-spacer v-if="$vuetify.breakpoint.xs"/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="$emit('delete')">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Entfernen</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!link">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="$emit('add')">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Spalte hinzufügen</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <v-overlay v-if="hover && !$vuetify.breakpoint.mobile"
                 color="primary"
                 :opacity="0.1"
                 absolute>
        <overlay-menu
            :is-link="link"
            :text="content.text"
            @delete="$emit('delete')"
            @copy="$emit('copy')"
            @edit="$emit('edit')"
            @add="$emit('add')"/>
      </v-overlay>
    </v-card>
  </v-hover>
</template>

<script>
import OverlayMenu from '../component/OverlayMenu';
import CanvasContent from './CanvasContent';
import VideoContent from "./VideoContent";
import QuizContent from "./QuizContent";
import MenuContent from "./MenuContent";
import RelationshipContent from "./RelationshipContent";

export default {
  components: {RelationshipContent, QuizContent, VideoContent, CanvasContent, OverlayMenu, MenuContent},
  props: {
    content: {
      required: true,
    },
    accentColor: {
      required: true
    }
  },
  data() {
    return {
      overlay: true,
    };
  },
  computed: {
    /**
     * get path to image in laravel storage
     */
    imageSource() {
      if (this.content?.content.name) {
        return `${process.env.VUE_APP_API}/storage/images/${this.content.content.name}`;
      }
      return null;
    },
    /**
     * get video id from youtube url
     * @returns {string|null}
     */
    videoId() {
      if (this.content?.content.link) {
        try {
          const url = new URL(this.content.content.link);
          const id = url.searchParams.get('v');
          if (id) {
            return id;
          }
        } catch (e) {
          console.error(e);
          return null;
        }
      }
      return null;
    },
    videoSource() {
      if (this.content?.content.name) {
        return `${process.env.VUE_APP_API}/storage/videos/${this.content.content.name}`;
      }
      return null;
    },
    image() {
      return this.content.mime_type === 'image';
    },
    divider() {
      return this.content.mime_type === 'divider';
    },
    youtube() {
      return this.content.mime_type === 'youtube';
    },
    audio() {
      return this.content.mime_type === 'audio';
    },
    text() {
      return this.content.mime_type === 'text';
    },
    canvas() {
      return this.content.mime_type === 'canvas';
    },
    quiz() {
      return this.content.mime_type === 'quiz';
    },
    relationship() {
      return this.content.mime_type === 'relationship';
    },
    link() {
      return this.content.mime_type === 'link';
    },
    video() {
      return this.content.mime_type === 'video';
    },
    embed() {
      return this.content.mime_type === 'embed';
    },
    menu() {
      return this.content.mime_type === 'menu';
    }
  },
  methods: {},
};
</script>

<style scoped>
.normalize >>> * {
  margin: 0 !important;
}

.video-container {
  position: relative;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover
}

.default-font {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: bold;
}

.font-color {
  color: var(--v-primary-base);
}

.max-height-30 {
  max-height: 30px;
}

</style>
