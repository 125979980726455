var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-2",attrs:{"color":"primary","min-width":"200px"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.text)+" ")]),(!_vm.isLink)?_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('copy')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("file_copy")])],1)]}}],null,false,2240857731)},[_c('span',[_vm._v("Duplizieren")])])],1):_vm._e(),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tool":"","icon":""},on:{"click":function($event){return _vm.$emit('edit')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Bearbeiten")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}])},[_c('span',[_vm._v("Entfernen")])])],1),(!_vm.isLink)?_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('add')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2074804282)},[_c('span',[_vm._v("Spalte hinzufügen")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }