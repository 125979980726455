import axios from "axios";

export class NetworkMedia {
    static saveImages(formData) {
        return axios.post('api/media/image', formData, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    static saveAudio(formData) {
        return axios.post('api/media/audio', formData, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    static saveVideo(formData) {
        return axios.post('api/media/video', formData, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    static deleteImage(filename) {
        return axios.delete('api/media/image', { data: { filename: filename}})
    }

    static deleteAudio(filename) {
        return axios.delete('api/media/audio', { data: { filename: filename}})
    }

    static deleteVideo(filename) {
        return axios.delete('api/media/video', { data: { filename: filename}})
    }

    static getMedia(config) {
        return axios.get('api/media', config)
    }

    static getAudio(config) {
        return axios.get('api/media/audio', config)
    }

    static getImages(config) {
        return axios.get('api/media/images', config)
    }

    static getVideos(config) {
        return axios.get('api/media/videos', config)
    }
}