import axios from "axios";

export class NetworkTag {
    static getTags(page, itemsPerPage, sortBy, sortDesc, config) {
        return axios.get('api/tag?page='+ page + '&itemsPerPage=' + itemsPerPage + '&sortBy=' + sortDesc + '&sortDesc=' + itemsPerPage, config)
    }

    static getTag(id, config) {
        return axios.get(`api/tag/${id}`, config)
    }

    static getTagFromSlug(slug, config) {
        return axios.get(`api/tag/slug/${slug}`, config)
    }

    static createTag(params, config) {
        return axios.post('api/tag', params, config)
    }

    static deleteTag(id, config) {
        return axios.delete(`api/tag/${id}`, config)
    }

    static changeNfcTag(nfcTag, config) {
        return axios.put(`api/tag/${nfcTag.id}`, nfcTag, config);
    }
}