<template>
  <v-dialog v-model="modelHelper" persistent max-width="700">
    <edit-content
        v-model="image.menu_title"
        :content-text="image.text"
        @close="$emit('input', false)"
        @save="save()"
        :disabled="!image.content.name">
      <v-card>
        <v-card-title>Ausgewähltes Bild
          <v-spacer/>
          <v-btn color="primary" @click="openMediaPool = true">
            Bild auswählen
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <v-img ref="image"
                     v-if="imageSource"
                     width="200"
                     height="200"
                     :src="imageSource"/>
              <div v-else>Bitte wähle ein Bild aus</div>
            </v-col>
            <v-col v-if="imageSource">
              <v-row>
                <v-col cols="12">
                  <div class="title">{{ image.content.name }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model.number="image.content.max_width" type="number"
                                label="Maximale Breite in Pixel"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model.number="image.content.max_height" type="number"
                                label="Maximale Höhe in Pixel"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle v-if="imageSource">
          Ohne Angabe wird das Bild automatisch skaliert
        </v-card-subtitle>

      </v-card>

      <v-dialog v-model="openMediaPool">
        <v-card>
          <v-card-title>Bitte wähle ein Logo</v-card-title>
          <mediapool :only-to-choose="true" mime-type="image" @choose="setImage"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </edit-content>
  </v-dialog>

</template>

<script>
import EditContent from "../component/EditContent";
import Mediapool from "../../media/Mediapool";

export default {
  components: {Mediapool, EditContent},
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      image: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          max_width: 0,
          max_height: 0,
          name: null,
        },
        order: null,
        key: null,
      },
      openMediaPool: false
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    /**
     * get full path of image file
     * @returns {string|null}
     */
    imageSource() {
      if (this.image?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/images/${this.image.content.name}`
      }
      return null
    }
  },
  methods: {
    setImage(image) {
      this.openMediaPool = false
      this.image.content.path = image.path
      this.image.content.name = image.name
    },
    save() {
      this.image.content.max_height = this.image.content.max_height !== "" ? this.image.content.max_height : null
      this.image.content.max_width = this.image.content.max_width !== "" ? this.image.content.max_width : null
      this.$emit('save', this.image)
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.image = JSON.parse(JSON.stringify(this.content))
        this.image.css_id = this.image.css_id === null ? "image-" + Date.now() : this.image.css_id;
      }
    }
  },
}
</script>

<style scoped>

</style>