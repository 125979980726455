// code from https://stackoverflow.com/questions/37539390/how-to-set-the-font-color-between-black-and-white-depending-on-the-color-of-the
function getContrastColor(hex) {
    const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    let hRed = parseInt(((hex.charAt(0) === "#") ? hex.substring(1, 7) : hex).substring(0, 2), 16)
    let hGreen = parseInt(((hex.charAt(0) === "#") ? hex.substring(1, 7) : hex).substring(2, 4), 16)
    let hBlue = parseInt(((hex.charAt(0) === "#") ? hex.substring(1, 7) : hex).substring(4, 6), 16)

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) {
        return "#000000";
    } else {
        return "#ffffff";
    }
}

export {
    getContrastColor
}