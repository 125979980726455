<template>
  <div class="my-8 mx-2">
    <h1 class="mb-2">{{ menu.content.title === null ? "Inhaltsverzeichnis" : menu.content.title }}</h1>
    <div v-for="(content, idx) in titles" :key="content.css_id">
      <a :style="{color: (color !== undefined && color !== null) ? color : $vuetify.theme.themes.light.primary}"
         class="menu-item" @click="$vuetify.goTo('#' + content.css_id, options )">{{
          (idx + 1) + ". " + content.menu_title
        }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      required: true
    },
    color: {
      required: true
    }
  },
  data() {
    return {
      options: {
        duration: 900,
        offset: 20,
      }
    }
  },
  computed: {
    contents() {
        return this.$store.state.content.nfcContents
    },
    titles() {
      const cleanContents = [];
      for (let i = 0; i < this.contents.length; i++) {
        let content = this.contents[i]
        for (let j = 0; j < content.items.length; j++) {
          let item = content.items[j];
          if (item.menu_title !== null) {
            cleanContents.push(item)
          }
        }
      }
      return cleanContents;
    },
  }
}
</script>

<style scoped>

.menu-item {
  font-size: 1rem !important;
}

</style>