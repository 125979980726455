<template>
  <v-dialog
      v-model="modelHelper"
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            :disabled="loading"
            @click="$emit('input', false)"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="error"
            text
            :loading="loading"
            @click="okBtn()"
        >
          {{ actionBtnName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    title: {
      required: false,
      type: String,
      default: 'Bist du dir sicher'
    },
    message: {
      required: false,
      type: String,
      default: 'Dies kann nicht rückgängig gemacht werden'
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    actionBtnName: {
      required: false,
      type: String,
      default: "Löschen"
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    okBtn() {
      this.$emit("confirm")
    }
  }
}
</script>

<style scoped>

</style>