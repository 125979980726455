<template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="auto">
            <h2 class="text-uppercase">{{ title }}</h2>
          </v-col>
          <v-col v-if="actionButtonText" class="text-right">
            <v-btn @click="$emit('clickBtn')" color="primary">{{ actionButtonText }}</v-btn>
          </v-col>
<!--          <v-col cols="12">-->
<!--            <v-text-field-->
<!--                v-model="search"-->
<!--                append-icon="search"-->
<!--                label="Suche"-->
<!--                single-line-->
<!--                hide-details-->
<!--            ></v-text-field>-->
<!--          </v-col>-->
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :loading-text="loadingText"
          :server-items-length="total"
          :options.sync="modelHelper"
          sort-by="created_at"
          :sort-desc="true"
          :items-per-page="10"
          @click:row="handleClick"
          no-data-text="Keine Daten vorhanden"
          no-results-text="Keine Einträge gefunden"
          :header-props="headerProps"
          :footer-props="footerProps">
        <template v-slot:item.created_at="{ item }">
          <slot name="created_at" :item="item"></slot>
        </template>
        <template v-slot:item.actions="{ item }">
          <slot name="actions" :item="item"></slot>
        </template>
        <template v-slot:item.copy="{ item }">
          <slot name="copy" :item="item"></slot>
        </template>
        <template v-slot:item.preview="{ item }">
          <slot name="preview" :item="item"></slot>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
export default {
  props: {
    // options
    value: {
      required: true
    },
    headers: {
      required: true
    },
    items: {
      required: true
    },
    title: {
      required: true,
      type: String
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    loadingText: {
      required: false,
      type: String,
      default: "Lade Daten..."
    },
    actionButtonText: {
      required: false,
      type: String,
      default: null
    },
    total: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      search: "",
      footerProps: {
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}'
      },
      headerProps: {
        sortByText: "Sortieren nach"
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleClick(value) {
      this.$emit('clickRow', value)
    }
  }
}
</script>

<style scoped>

</style>