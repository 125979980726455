<template>
  <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      small
      fixed
      bottom
      right
      :style="{color: getContrastColor((color !== undefined && color !== null) ? color : $vuetify.theme.themes.light.primary)}"
      :color="color !== undefined ? color : 'primary'"
      @click="toTop"
  >
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
import {getContrastColor} from "../helper/ColorHelper";

export default {
  props: {
    color: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      fab: false,
      getContrastColor,
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 200
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>