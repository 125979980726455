<template>
  <div class="ma-2">
    <!--    MIMETYPE TEXT-->
    <div v-if="nfcContent.mime_type === 'text'" class="default-font" v-html="nfcContent.content.text_content"/>

    <!--    MIMETYPE DIVIDER-->
    <hr v-if="nfcContent.mime_type === 'divider'" class="divider"
        :style="`height: ${nfcContent.content.thickness}px;`"/>

    <!--    MIMETYPE IMAGE-->
    <img v-if="nfcContent.mime_type === 'image'" class="image"
         :alt="imageSource"
         :style="`max-height: ${nfcContent.content.max_height <= 0 ? 'none' : nfcContent.content.max_height + 'px'};
                     max-width:${nfcContent.content.max_width <= 0 ? 'none' : nfcContent.content.max_width + 'px'};`"
         :src="imageSource"
    />

    <!--    MIMETYPE YOUTUBE-->
    <div v-if="nfcContent.mime_type === 'youtube'" class="video-container"
         :style="`padding-bottom: ${nfcContent.content.max_height <= 0 ? '56.25%' : nfcContent.content.max_height + 'px'};`">
      <iframe
          :style="`max-height: ${nfcContent.content.max_height <= 0 ? 'none' : nfcContent.content.max_height + 'px'};
                     max-width:${nfcContent.content.max_width <= 0 ? 'none' : nfcContent.content.max_width + 'px'};`"
          :src="'https://www.youtube-nocookie.com/embed/' + videoId"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
    </div>

    <!--    MIMETYPE AUDIO-->
    <audio-player v-if="nfcContent.mime_type === 'audio'" flat :file="audioSource" :color="accentColor"
                  :background="background"
                  :auto-play="nfcContent.content.autoplay"/>

    <!--        MIMETYPE CANVAS-->
    <canvas-content v-if="nfcContent.mime_type === 'canvas'"
                    :card-width="cardWidth"
                    :color="accentColor"
                    :content="nfcContent.content"/>

    <!--         MIMETYPE QUIZ-->
    <quiz-content v-if="nfcContent.mime_type === 'quiz' && nfcContent.content.question" :nfc-content="nfcContent"/>

    <!--         MIMETYPE RELATIONSHIP-->
    <relationship-content v-if="nfcContent.mime_type === 'relationship'" :mobile-preview="mobilePreview" :nfc-content="nfcContent" :color="accentColor"/>

    <!--    MIMETYPE VIDEO-->
    <video-content v-if="nfcContent.mime_type === 'video'"
                   :video="nfcContent"
                   :controls="true"/>

    <!--    MIMETYPE EMBED-->
    <div v-if="nfcContent.mime_type === 'embed'" class="normalize"
         v-html="nfcContent.content.text"/>

    <!--    MIMETYPE MENU-->
    <menu-content v-if="nfcContent.mime_type === 'menu'" :menu="nfcContent" :color="accentColor"/>

  </div>
</template>

<script>
import QuizContent from "../../tag/contents/QuizContent";
import VideoContent from "../../tag/contents/VideoContent";
import CanvasContent from "../../tag/contents/CanvasContent";
import AudioPlayer from "../../../components/AudioPlayer";
import MenuContent from "../../tag/contents/MenuContent";
import RelationshipContent from "../../tag/contents/RelationshipContent";

export default {
  components: {RelationshipContent, MenuContent, AudioPlayer, CanvasContent, VideoContent, QuizContent},
  props: {
    nfcContent: {
      required: true
    },
    cardWidth: {
      required: false,
      type: String
    },
    accentColor: {
      required: true
    },
    mobilePreview: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      answer: null
    }
  },
  /**
   * redirect to specific URL
   */
  created() {
    if (this.nfcContent.mime_type === 'link') {
      window.location.href = this.nfcContent.content.url
    }
  },
  computed: {
    /**
     * get full path of image file
     * @returns {string|null}
     */
    imageSource() {
      if (this.nfcContent.mime_type === 'image' && this.nfcContent?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/images/${this.nfcContent.content.name}`
      }
      return null
    },
    /**
     * get full path of audio file
     * @returns {string|null}
     */
    audioSource() {
      if (this.nfcContent.mime_type === 'audio' && this.nfcContent?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/audio/${this.nfcContent.content.name}`
      }
      return null
    },
    /**
     * get video id from url
     * @returns {string|null}
     */
    videoId() {
      if (this.nfcContent.content.link) {
        try {
          const url = new URL(this.nfcContent.content.link)
          const id = url.searchParams.get("v")
          if (id) {
            return id
          }
        } catch (e) {
          console.error(e)
          return null
        }
      }
      return null
    },
    background() {
      return this.$store.state.helper.backgroundColor
    }
  }
}
</script>

<style scoped>

.divider {
  border: none;
  color: #333;
  background-color: #333;
}

.video-container {
  position: relative;
  height: 0;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.default-font {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: bold;
}

</style>