<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700">
    <edit-content
        v-model="canvas.menu_title"
        :content-text="canvas.text"
        :disabled="canvas.content.stroke_width === null || canvas.content.stroke_width <= 0"
        @close="$emit('input', false)"
        @save="save">
      <v-text-field v-model.number="canvas.content.stroke_width" type="number" label="Dicke der Linie in Pixel"/>
      <div class="d-flex align-center mb-8">
        <v-btn :color="canvas.content.stroke" :style="{color: getContrastColor(canvas.content.stroke)}"
               @click="colorDialog = true">Linienfarbe
        </v-btn>
        <hr class="mx-4"
            :style="`height: ${canvas.content.stroke_width}; background: ${canvas.content.stroke}; width: 100px; border: 0`"/>
      </div>
      <v-text-field v-model.number="canvas.content.max_width" type="number" label="Maximale Breite in Pixel"/>
      <v-text-field v-model.number="canvas.content.max_height" type="number" label="Maximale Höhe in Pixel"/>
      <div class="subtitle-2">
        Ohne Angabe wird die Zeichenfläche automatisch skaliert.
        </div>
      <div>
        <v-icon color="primary" small>info</v-icon> Beim Bild Nachzeichnen ist es meistens am Besten, die "Maximale Höhe in Pixel" leer zu lassen, damit die Höhe automatisch am Bild berechnet wird und kein Weißraum entsteht.
      </div>

      <div class="subtitle-1 mt-8">
        Bild einfügen, dass nachgezeichnet werden soll (optional)
      </div>
      <v-row>
        <v-col cols="auto">
          <v-btn color="primary" class="my-2" @click="openMediaPool = true">
            {{ canvas.content.imagePath ? "Bild ändern" : "Bild auswählen" }}
          </v-btn>
        </v-col>
        <v-col v-if="canvas.content.image_path">
          <v-btn color="primary" class="my-2" text @click="canvas.content.image_path = null">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-img ref="image"
             width="200"
             height="200"
             v-if="canvas.content.image_path"
             :src="canvas.content.image_path"/>
    </edit-content>

    <v-dialog v-model="openMediaPool">
      <v-card>
        <v-card-title>Bitte wähle ein Logo</v-card-title>
        <mediapool :only-to-choose="true" mime-type="image" @choose="setImage"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="colorDialog" max-width="300">
      <color-picker v-model="canvas.content.stroke" default-color="#000000"/>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import Mediapool from "../../media/Mediapool";
import {getContrastColor} from "@/helper/ColorHelper";
import ColorPicker from "@/components/ColorPicker";


export default {
  components: {
    ColorPicker,
    EditContent,
    Mediapool
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      canvas: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          max_width: null,
          max_height: null,
          image_path: null,
          stroke_width: 5,
          stroke: "#000000"
        },
        order: null,
      },
      openMediaPool: false,
      colorDialog: false,
      getContrastColor
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    setImage(image) {
      this.openMediaPool = false
      this.canvas.content.image_path = image.path
    },
    save() {
      this.canvas.content.max_height = this.canvas.content.max_height !== "" ? this.canvas.content.max_height : null
      this.canvas.content.max_width = this.canvas.content.max_width !== "" ? this.canvas.content.max_width : null
      this.$emit('save', this.canvas)
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.canvas = JSON.parse(JSON.stringify(this.content))
        this.canvas.css_id = this.canvas.css_id === null ? "canvas-" + Date.now() : this.canvas.css_id;
      }
    }
  }
}
</script>

<style scoped>

</style>