<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700">
    <validation-observer v-slot="{invalid}">
      <form>
      <edit-content
          disable-menu-checkbox
          :content-text="menu.text"
          :disabled="invalid"
          @close="$emit('input', false)"
          @save="$emit('save', menu)">
        <validation-provider
            v-slot="{errors}"
            rules="required"
        >
        <v-text-field v-model="menu.content.title" placeholder="Inhaltsverzeichnis" type="text" label="Titel" :error-messages="errors"/>
        </validation-provider>
      </edit-content>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import {extend, setInteractionMode, ValidationProvider, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";

setInteractionMode('aggressive')

extend('required', {
  ...required,
  message: 'Bitte gebe einen Titel für das Menü an',
})


export default {
  components: {
    EditContent, ValidationProvider, ValidationObserver
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      menu: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          title: null,
        },
        order: null,
        key: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.menu = JSON.parse(JSON.stringify(this.content))
        this.menu.css_id = this.menu.css_id === null ? "menu-" + Date.now() : this.menu.css_id;
        this.menu.content.title = this.menu.content.title === null ? "Inhaltsverzeichnis" : this.menu.content.title
      }
    }
  }
}
</script>

<style scoped>

</style>