<template>
    <video width="100%" height="auto" :controls="controls" :autoplay="!!video.content.autoplay && !disableAutoplay"
           :style="{'max-width': video.content.max_width && !scaleTo100Width ? video.content.max_width + 'px' : null,
         'max-height': video.content.max_height && !scaleTo100Width ? video.content.max_height + 'px' : null}">
      <source :src="videoSource"/>
    </video>
</template>

<script>
export default {
  props: {
    video: {
      required: true
    },
    controls: {
      required: false,
      type: Boolean,
      default: false
    },
    disableAutoplay: {
      required: false,
      type: Boolean,
      default: false
    },
    scaleTo100Width: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * get full path of video file
     * @returns {string|null}
     */
    videoSource() {
      if (this.video?.content?.name) {
        return `${process.env.VUE_APP_API}/storage/videos/${this.video.content.name}`
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>