<template>
  <v-container>
    <data-table title="NFC Tags"
                :headers="computedHeaders"
                :items="tags"
                :loading="loading"
                :total="totalTags"
                v-model="options"
                @clickRow="handleClickOnRow"
                class="row-pointer"
                loading-text="NFC Tags werden geladen">
      <template v-slot:created_at="{item}">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
      </template>
      <template v-slot:actions="{item}">
        <v-icon
            @click.stop="openConfirmDialog(item)">
          delete
        </v-icon>
      </template>
      <template v-slot:copy="{item}">
        <v-btn text class="lower-case" color="primary"
               @click.stop="copyLink(item.url)">
          <v-icon left>content_copy</v-icon>
          {{ item.url }}
        </v-btn>
      </template>
      <template v-slot:preview="{item}">
        <v-btn text color="primary" @click.stop target="_blank" :href="item.url">
          <v-icon>open_in_new</v-icon>
        </v-btn>
      </template>
    </data-table>

    <!--    CARD TO CREATE NFC TAGS-->
    <v-card class="mt-8">
      <v-form @submit.prevent="createTag()">
        <v-card-title>NFC Tag erstellen</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="tag.name" label="Name"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="tag.description" label="Beschreibung"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="reset()" :disabled="loadingCreateBtn">Verwerfen</v-btn>
          <v-btn class="ma-2" color="primary" type="submit" :loading="loadingCreateBtn">Erstellen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <confirmation-dialog v-model="confirmDialog"
                         :loading="loadingDeleteBtn"
                         title="NFC Tag wirklich löschen?"
                         @confirm="deleteNfcTag()"/>
    <notification v-model="showAlert" :text="alertText" :type="alertType"/>
  </v-container>
</template>

<script>
import DataTable from "../../components/table/DataTable";
import {NetworkTag} from "../../helper/network/tag/NetworkTag";
import Notification from "../../components/Notification";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";

export default {
  components: {ConfirmationDialog, Notification, DataTable},
  data() {
    return {
      headers: [
        {text: 'Name', value: 'name', mobile: true},
        {text: 'Erstellt am', value: 'created_at', mobile: false},
        {text: 'Link', value: 'copy', sortable: false, mobile: false},
        {text: 'Vorschau', value: 'preview', sortable: false, mobile: true},
        {text: '', value: 'actions', sortable: false, mobile: true}
      ],
      totalTags: 0,
      tags: [],
      options: {},
      loading: false,
      tag: {
        name: null,
        description: null
      },
      loadingCreateBtn: false,
      loadingDeleteBtn: false,
      alertText: "",
      alertType: null,
      showAlert: false,
      confirmDialog: false,
      nfcTagIdToDelete: null,
    }
  },
  computed: {
    /**
     * hide some headers if table switch to mobile view
     */
    computedHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return this.headers.filter(head => head.mobile)
      }
      return this.headers
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchTags()
      },
      deep: true,
    },
  },
  methods: {
    async fetchTags() {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const {data} = await NetworkTag.getTags(page, itemsPerPage, sortBy, sortDesc)
        this.tags = data.data
        this.totalTags = data.meta.total
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.tag.name = null
      this.tag.description = null
    },
    /**
     * Create NFC tag with name and description
     */
    async createTag() {
      this.loadingCreateBtn = true
      try {
        await NetworkTag.createTag(this.tag)
        await this.fetchTags()
        this.showSuccessAlert("NFC Tag erfolgreich erstellt")
      } catch (e) {
        console.error(e)
        this.showErrorAlert("NFC Tag konnte nicht erstellt werden")
      } finally {
        this.loadingCreateBtn = false
        this.reset()
      }
    },
    /**
     * Open confirmation dialog to delete nfc tag
     */
    openConfirmDialog(item) {
      this.confirmDialog = true;
      this.nfcTagIdToDelete = item.id
    },
    async deleteNfcTag() {
      this.loadingDeleteBtn = true
      try {
        await NetworkTag.deleteTag(this.nfcTagIdToDelete)
        await this.fetchTags()
        this.showSuccessAlert("NFC Tag erfolgreich gelöscht")
      } catch (e) {
        console.error(e)
        this.showErrorAlert("NFC Tag konnte nicht gelöscht werden")
      } finally {
        this.loadingDeleteBtn = false
        this.confirmDialog = false
      }
    },
    /**
     * Create an element to copy the URL to the clipboard.
     * Then remove the element again.
     */
    copyLink(url) {
      const tempText = document.createElement("input");
      tempText.value = url;
      document.body.appendChild(tempText);
      tempText.select();

      document.execCommand("copy");
      document.body.removeChild(tempText);
      this.showSuccessAlert("Link kopiert")
    },
    /**
     * Redirect users to the edit nfc tag page (EditNfcTag.vue)
     */
    handleClickOnRow(value) {
      this.$router.push(`/nfc/tags/edit?id=${value.id}`)
    },
    /**
     * show success alert
     * @param message
     */
    showSuccessAlert(message) {
      this.alertText = message
      this.alertType = "success"
      this.showAlert = true;
    },
    /**
     * show error alert
     * @param message
     */
    showErrorAlert(message) {
      this.alertText = message
      this.alertType = "error"
      this.showAlert = true;
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.lower-case {
  text-transform: none;
}

</style>