<template>
  <v-container>
    <v-dialog v-model="modelHelper" persistent max-width="600">
      <v-card>
        <v-card-title>Datei Upload</v-card-title>
        <v-card-subtitle>Hier kannst du Dateien hochladen, die später für NFC Tags verwendet werden können
        </v-card-subtitle>
        <v-card-text>
          <v-tabs class="mb-4" v-model="mimeType" icons-and-text>
            <v-tab v-for="content in contents" :key="content.text">
              {{ content.text }}
              <v-icon>{{ content.icon }}</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="mimeType">
            <v-tab-item v-for="content in contents" :key="content.text">
              <v-row no-gutters align="center">
                <v-col>
                  <v-file-input
                      v-model="file"
                      :placeholder="content.placeholder"
                      :accept="content.accept"
                      :label="content.label">
                  </v-file-input>
                </v-col>
                <v-col cols="auto" class="ps-1">
                  <v-progress-circular
                      v-if="progress !== null"
                      :rotate="360"
                      :value="progress"
                      color="primary"/>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="$emit('input', false)" :disabled="loading">Abbrechen</v-btn>
          <v-btn @click="upload()" color="primary" class="ma-2" :loading="loading" :disabled="file === null">Hochladen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      file: null,
    },
    loading: {
      required: true,
      type: Boolean
    },
    progress: {
      required: false,
      default: null,
    }
  },
  data() {
    return {
      file: null,
      mimeType: 0,
      contents: [
        {
          text: "Bilder",
          icon: "image",
          placeholder: "Lade deine Bilder hier hoch",
          accept: "image/*",
          label: "Bilder Upload",
          mimeType: 'image'
        },
        {
          text: "Audio",
          icon: "volume_up",
          placeholder: "Lade deine Audio Dateien hier hoch",
          accept: "audio/*",
          label: "Audio Upload",
          mimeType: 'audio'
        },
        {
          text: "Video",
          icon: "movie",
          placeholder: "Lade dein Video hier hoch",
          accept: "video/*",
          label: "Video Upload",
          mimeType: 'video'
        },
      ],
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    upload() {
      this.$emit("upload", this.file, this.contents[this.mimeType].mimeType)
    }
  },
  watch: {
    value(newVal) {
      if (newVal)
        this.file = null
    },
    mimeType() {
      this.file = null
    }
  }
}
</script>

<style scoped>

</style>