<template>
  <v-dialog v-model="modelHelper" max-width="700">
    <v-card>
      <v-card-title>Spalten bearbeiten</v-card-title>
      <v-card-text>
        Wie breit sollen die jeweiligen Spalten sein? (Eine Zeile ist in <strong>12</strong> Spalten aufgeteilt)
        <v-row class="mt-2">
          <v-col v-for="(content, idx) of contentsWithIndex" :key="content.css_id">
            <v-text-field outlined dense :placeholder="'Spalte ' + (idx+1)" type="number"
                          v-model.number="columns[idx]"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col v-for="(column, idx) of columns" :cols="column" :key="idx">
            <hr class="line">
          </v-col>
        </v-row>
        Aktuelle Summe: {{ sum }} <br>
        <strong>Wichtig: Die Summe aller Spalten muss exakt 12 betragen.</strong>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" no-gutters>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn @click="modelHelper = false" class="ma-2">Abbrechen</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="onSave" color="primary" :disabled="sum !== 12 || columns.length < contentsLength" class="ma-2">Speichern</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    indexToEdit: {
      required: true,
    }
  },
  data() {
    return {
      columns: []
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    contents() {
      return this.$store.state.content.nfcContents
    },
    contentsLength() {
      return this.contents.find(content => content.index === this.indexToEdit).items.length
    },
    /**
     * Get content with specific order
     */
    contentsWithIndex() {
      if (this.indexToEdit === null || this.indexToEdit === undefined) {
        return;
      }
      const contentWithOrder = this.contents.find(content => content.index === this.indexToEdit)
      return contentWithOrder.items
    },
    /**
     * Get sum from percentages
     */
    sum() {
      let count = 0;
      for (let i = this.columns.length; i--;) {
        count += this.columns[i];
      }
      return count
    }
  },
  methods: {
    onSave() {
      let contentsWithIndex = JSON.parse(JSON.stringify(this.contentsWithIndex));
      for (let i = 0; i < contentsWithIndex.length; i++) {
        let content = contentsWithIndex[i]
        content.col_width = this.columns[i]
        this.$store.commit("content/updateContent", content)
      }
      this.modelHelper = false;
    }
  }
}
</script>

<style scoped>
.line {
  height: 20px;
  background-color: lightgrey;
  border-color: black;
}

</style>