<template>
  <v-card>
    <v-card-title>
      Benutzerdefiniertes CSS
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="mb-4">
        <v-spacer/>
        <v-col cols="auto">
          <v-btn color="primary" @click="$emit('close')">Speichern</v-btn>
        </v-col>
      </v-row>
      <prism-editor v-model="modelHelper" class="my-editor" :highlight="highlighter"
                    line-numbers/>
      <v-row no-gutters class="mt-4">
        <v-spacer/>
        <v-col cols="auto">
          <v-btn color="primary" @click="$emit('close')">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import Prism Editor
import {PrismEditor} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  components: {
    PrismEditor
  },
  props: {
    value: {
      required: true
    },
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.css, "css"); // languages.<insert language> to return html with markup
    },
  }
}
</script>

<style scoped>
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px;
  height: 400px;
}

</style>