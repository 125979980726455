var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.modelHelper),callback:function ($$v) {_vm.modelHelper=$$v},expression:"modelHelper"}},[_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nutzer anlegen")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Name ")]},proxy:true}],null,true),model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("E-Mail ")]},proxy:true}],null,true),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',[_c('user-fields',{model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Passwort","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Passwort ")]},proxy:true}],null,true),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Passwort","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Passwort bestätigen ")]},proxy:true}],null,true),model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)]],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Anlegen ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }