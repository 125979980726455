<template>
  <v-container fluid>
  <v-row justify="center">
    <v-col sm="12" lg="6">
      <v-card class="pa-4 my-8 mx-2">
        <validation-observer
            v-slot="{invalid}">
          <form @submit.prevent="submit">
            <h1 class="text-uppercase">login</h1>
            <validation-provider
                mode="eager"
                v-slot="{errors}"
                name="E-mail"
                rules="required|email"
            >
              <v-text-field v-model="email"
                            label="E-mail"
                            type="email"
                            :error-messages="errors"
                            required/>
            </validation-provider>
            <validation-provider
                mode="aggressive"
                v-slot="{errors}"
                name="Passwort"
                rules="required">
              <v-text-field v-model="password"
                            label="Passwort"
                            :error-messages="errors"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            required/>
            </validation-provider>
            <v-btn :disabled="invalid" color="primary"
                   type="submit"
                   :loading="loading">
              Anmelden
            </v-btn>
          </form>
        </validation-observer>
      </v-card>
    </v-col>
  </v-row>
  <notification v-model="showAlert" :text="alertText" type="error" />
  </v-container>
</template>

<script>
import {required, email} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import Notification from "../../components/Notification";


extend('required', {
  ...required,
  message: '{_field_} ist erforderlich',
})

extend('email', {
  ...email,
  message: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
})

export default {
  components: {
    Notification,
    ValidationObserver, ValidationProvider
  },
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      loading: false,
      showAlert: false,
      alertText: "Bitte versuchen Sie es erneut"
    }
  },
  methods: {
    /**
     * Send login request and redirect user to dashboard
     */
    async submit() {
      this.loading = true
      try {
        await this.$store.dispatch('auth/login', {email: this.email, password: this.password})
        this.loading = false
        await this.$router.push('/');
      }
      catch (err) {
        this.reset()
      }
    },
    /**
     * reset loading button and show notification
     */
    reset() {
      this.showAlert = true
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>