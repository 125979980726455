<template>
  <div class="trumbowyg-editor">
    <v-row v-if="undoButton" justify="end" no-gutters>
      <v-btn icon color="black" @click="doUndo()" :disabled="this.undo === 0">
        <v-icon>undo</v-icon>
      </v-btn>
      <v-btn icon color="black" @click="doRedo()" :disabled="this.undo === null || this.undo >= this.commands.length-1">
        <v-icon>redo</v-icon>
      </v-btn>
    </v-row>
    <trumbowyg v-model="modelHelper" :config="trumbowygConfig" autocomplete="off" autocorrect="off" autocapitalize="off"
               spellcheck="false"/>
    <div v-if="maxCharacter">Bitte nicht mehr als {{characters}}/{{maxCharacter}} Zeichen eingeben.</div>

    <v-dialog v-model="openMediaPool">
      <v-card>
        <v-card-title>Bitte wähle ein Logo</v-card-title>
        <mediapool :only-to-choose="true" mime-type="image" @choose="setImage"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";
import "trumbowyg/dist/plugins/specialchars/ui/trumbowyg.specialchars.min.css";
import "trumbowyg/dist/plugins/specialchars/trumbowyg.specialchars.min.js";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css"
import "trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js";
import 'jquery-resizable-dom';
import "trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.min.js";
import Mediapool from "../../media/Mediapool";

export default {
  components: {Trumbowyg, Mediapool},
  props: {
    value: {
      required: true
    },
    undoButton: {
      required: false,
      type: Boolean,
      default: true
    },
    smallConfig: {
      required: false,
      type: Boolean,
      default: false
    },
    maxCharacter: {
      required: false,
      type: Number,
      default: null
    }
  },
  data() {
    return {
      commands: [""],
      undo: null,
      openMediaPool: false,
      changeByUndoBtn: false,
      lastValue: null,
      trumbowygConfig: {
        minimalLinks: true,
        defaultLinkTarget: '_blank',
        lang: 'de',
        btnsDef: {
          image: {
            fn: () => {
              this.openMediaPool = true;
            },
            ico: 'insertImage'
          }
        },
        btns: [
          ['strong', 'italic', 'formatting', 'fontfamily', 'fontsize', 'foreColor', 'backColor'], // 'lineheight'],
          ['specialChars', 'superscript', 'subscript'],
          ['link'],
          this.smallConfig ? null : ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          this.smallConfig ? null : ['unorderedList', 'orderedList'],
          this.smallConfig ? null : ['horizontalRule'],
          ['emoji'],
          this.smallConfig ? null : ['viewHTML'],
          this.smallConfig ? null : ['image'],
        ],
        plugins: {
          fontsize: {
            allowCustomSize: true,
            sizeList: [
              '8px', '10px', '12px',
              '14px', '16px', '18px',
              '20px', '22px', '24px',
              '26px', '28px', '30px',
              '32px', '34px', '36px',
              '38px', '40px', '42px',
              '44px', '46px', '48px',
              '50px', '52px', '54px',
              '56px', '58px', '60px',
              '62px', '64px', '66px',
              '68px', '70px', '72px',
              '74px', '76px', '78px',
              '80px', '82px', '84px',
              '86px', '88px', '90px',
              '92px', '94px', '96px',
              '98px', '100px'
            ]
          },
          specialchars: {
            symbolList: [
              '2190', '2192', '2191', '2193', '1f850', '1f852', '1f851', '1f853', null,
              '2194', '2195', '1f854', '1f855', '1f856', '1f857', '2b09', '2b08', '2b0a', '2b0b', null,
              '00A2', '00A5', '00A4', '2030',
              '00A9', '00AE', '2122', '2023',
              '25B6', '2B29', '25C6', '2211', '2243', '2264', '2265',
            ]
          },
          fontfamily: {
            fontList: [
              {name: 'Arial', family: 'Arial, Helvetica, sans-serif'},
              {name: 'Arial Black', family: 'Arial Black, Gadget, sans-serif'},
              {name: 'Comic Sans', family: 'Comic Sans MS, Textile, cursive, sans-serif'},
              {name: 'Courier New', family: 'Courier New, Courier, monospace'},
              {name: 'Georgia', family: 'Georgia, serif'},
              {name: 'Impact', family: 'Impact, Charcoal, sans-serif'},
              {name: 'Lucida Console', family: 'Lucida Console, Monaco, monospace'},
              {name: 'Lucida Sans', family: 'Lucida Sans Uncide, Lucida Grande, sans-serif'},
              {name: 'Palatino', family: 'Palatino Linotype, Book Antiqua, Palatino, serif'},
              {name: 'Tahoma', family: 'Tahoma, Geneva, sans-serif'},
              {name: 'Times New Roman', family: 'Times New Roman, Times, serif'},
              {name: 'Trebuchet', family: 'Trebuchet MS, Helvetica, sans-serif'},
              {name: 'Verdana', family: 'Verdana, Geneva, sans-serif'},
              {name: 'Berlin Sans FB', family: 'Berlin Sans FB, Helvetica, sans-serif'},
              {name: 'Century Gothic', family: 'Century Gothic, Helvetica, sans-serif'},
              {name: 'Tw Cen MT', family: 'Tw Cen MT, Helvetica, sans-serif'},
              {name: 'Comic Neue', family: 'Comic Neue, Helvetica, sans-serif'},
            ]
          }
        },
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      /**
       * fill commands array for redo/undo
       */
      set(val) {
        if (this.changeByUndoBtn) {
          this.changeByUndoBtn = false
          return
        }
        if (this.lastValue === val) {
          return
        }
        this.lastValue = val
        this.$emit('input', val)

        if (this.undo === null) {
          this.commands.push(val)
        } else {
          this.commands.splice(this.undo + 1, this.commands.length - (this.undo + 1), val)
          this.undo++
        }
        if (this.commands.length > 25) {
          this.commands.shift()
        }
      }
    },
    characters() {
      // strip out tags and line breaks
      if (this.modelHelper === null) {
        return 0;
      }
      const cleanCode = this.modelHelper.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm,"").replace('&nbsp;','');
      return cleanCode.trim().length;
    }
  },
  methods: {
    /**
     * set Image from Mediapool
     */
    setImage(image) {
      this.openMediaPool = false
      this.modelHelper = this.modelHelper +
          `<img src="${image.path}" style="width: 100%; max-width: 200px; height: auto; max-height: 200px;"/>`
    },
    /**
     * Get a previous element from the array and overwrite the Property input with it.
     */
    doUndo() {
      if (this.undo === null) {
        this.undo = this.commands.length - 1
      }
      if (this.undo <= 0) {
        this.undo = 0
        return
      }
      this.undo = this.undo - 1;
      this.changeByUndoBtn = true
      this.$emit('input', this.commands[this.undo])
    },
    /**
     * Get the next element from the array and overwrite the input property with it
     */
    doRedo() {
      if (this.undo === null) {
        return
      }
      if (this.undo >= this.commands.length - 1) {
        this.undo = this.commands.length - 1
        return
      }
      this.undo = this.undo + 1
      this.changeByUndoBtn = true
      this.$emit('input', this.commands[this.undo])
    }
  },
  created() {
    /** ===========================================================
     * de.js
     * German translation for Trumbowyg
     * ===========================================================
     */
    window.jQuery.trumbowyg.langs.de = {
      viewHTML: "HTML anzeigen",
      formatting: "Formatierung",
      p: "Absatz",
      blockquote: "Zitat",
      code: "Code",
      header: "Überschrift",
      bold: "Fett",
      italic: "Kursiv",
      strikethrough: "Durchgestrichen",
      underline: "Unterstrichen",
      strong: "Fett",
      em: "Betont",
      del: "Gelöscht",
      unorderedList: "Ungeordnete Liste",
      orderedList: "Geordnete Liste",
      lineheight: "Absatz",
      image: "Bild",
      insertImage: "Bild einfügen",
      insertVideo: "Video einfügen",
      link: "Link",
      createLink: "Link einfügen",
      unlink: "Link entfernen",
      justifyLeft: "Links ausrichten",
      justifyCenter: "Zentrieren",
      justifyRight: "Rechts ausrichten",
      justifyFull: "Blocksatz",
      small: "klein",
      horizontalRule: "Horizontale Linie einfügen",
      removeformat: "Formatierung entfernen",
      fullscreen: "Vollbild",
      close: "Schließen",
      submit: "Bestätigen",
      reset: "Abbrechen",
      required: "Erforderlich",
      description: "Beschreibung",
      title: "Titel",
      text: "Text",
      fontFamily: "Schriftart",
      fontsize: "Schriftgröße",
      fontsizes: {
        'custom': 'Benutzerdefiniert'
      },
      fontCustomSize: {
        title: 'Benutzerdefinierte Größe',
        label: 'Schriftgröße',
        value: '72px'
      },
      foreColor: "Schriftfarbe",
      backColor: "Texthervorhebungsfarbe",
      undo: "Rückgängig",
      redo: "Wiederherstellen",
      insertAudio: "Audio hinzufügen",
      specialChars: "Formen",
      lineheights: {
        '0.9': 'Klein',
        'normal': 'Normal',
        '1.5': 'Groß',
        '2.0': 'Extra Groß'
      }
    };
  }
}
</script>

<style>
@font-face {
  font-family: "Comic Neue";
  src: local("Comic Neue"),
  url(../../../fonts/Comic_Neue/ComicNeue-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Comic Neue";
  src: local("Comic Neue"),
  url(../../../fonts/Comic_Neue/ComicNeue-Bold.ttf) format("truetype");
  font-weight: 900;
}

.trumbowyg-editor .trumbowyg-dropdown button[class^="trumbowyg-fontsize"],
.trumbowyg-editor .trumbowyg-dropdown button[class^="trumbowyg-fontsize"] span {
  height: auto !important;
  min-height: 35px !important;
  line-height: 1.2em !important;
}

.trumbowyg-editor .trumbowyg-box, #tx2 .trumbowyg-editor {
  min-height: 100px !important;
}

.trumbowyg-editor .trumbowyg-box .trumbowyg-editor {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: bold;
}

.trumbowyg-editor {
  padding: 0;
}

.trumbowyg-editor .trumbowyg-editor {
  padding: 16px;
}

</style>