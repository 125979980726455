<template>
  <v-dialog v-model="modelHelper" v-if="content" persistentm max-width="700">
    <edit-content
        v-model="embed.menu_title"
        :content-text="embed.text + ' (Audio, Video, ...)'"
        id="editor"
        @close="$emit('input', false)"
        @save="onSave"
        :disabled="!embed.content.text">
      <div class="mb-4" v-html="embed.content.text"></div>
      <v-textarea outlined label="Bitte füge hier das iframe ein" v-model="embed.content.text"/>
      <v-card-subtitle><strong>Anleitung:</strong><br>
        <ol>
          <li>
            Öffne die Seite aus der du ein Lied, Podcast etc. einbetten möchtest (z.B. soundcloud.com).
          </li>
          <li>
            Suche nach einen Button um den Link zu teilen (bzw. zu sharen).
          </li>
          <li>
            Suche nach einbetten bzw. embed und kopiere den Code in das obige Textfeld.
          </li>
          <li>
            Wenn alles geklappt hat, wird über dem Textfeld das entsprechende Medium eingeblendet (anderenfalls siehst
            du nur einen Text).
          </li>
        </ol>
        <br>Du kannst die Breite und Höhe des iframes selber anpassen. Dazu kannst du die Pixelangabe bzw. Prozentangabe
        im Code bei "width" oder "height" anpassen.
      </v-card-subtitle>
    </edit-content>
  </v-dialog>
</template>

<script>
import 'trumbowyg/dist/ui/trumbowyg.css';
import EditContent from "../component/EditContent";

export default {
  components: {
    EditContent,
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      embed: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          text: null
        },
        order: null,
        key: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSave() {
      // Soundcloud embed has interpret with nowrap (wider then col)
      this.embed.content.text = this.embed.content.text.replace("white-space: nowrap;", "")
      this.$emit('save', this.embed)
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.embed = JSON.parse(JSON.stringify(this.content))
        this.embed.css_id = this.embed.css_id === null ? "embed-" + Date.now() : this.embed.css_id;
      }
    }
  }
}
</script>

<style scoped>
li {
  line-height: 2.0;
}

</style>