<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700">
    <edit-content
        v-model="link.menu_title"
        :content-text="link.text"
        :disabled="!link.content.url || showError"
        @close="$emit('input', false)"
        @save="$emit('save', link)">
      <iframe width="100%" height="300" :src="link.content.url"
              title="Link" frameborder="0"/>
      <v-text-field v-model="link.content.url" label="Link zur Seite" :persistent-hint="true"
                    hint="z.B.: https://example.com oder https://www.example.com"
                    :error-messages="showError ? 'Bitte gebe eine gültige URL ein (https://...)' : null"/>
    </edit-content>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";

export default {
  components: {
    EditContent
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      showError: false,
      link: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          url: null
        }, order: 0
      }
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    url() {
      return this.link.content.url
    }
  },
  methods: {
    isValidUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.link = JSON.parse(JSON.stringify(this.content))
        this.link.css_id = this.link.css_id === null ? "link-" + Date.now() : this.link.css_id;
      }
    },
    url(newValue) {
      this.showError = !this.isValidUrl(newValue);
    }
  }
}
</script>

<style scoped>

</style>