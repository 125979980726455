<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700" :retain-focus="false">
    <form>
      <edit-content
          v-model="relationships.menu_title"
          :content-text="relationships.text"
          :disabled="relationships.content.pairings.length <= 0"
          @close="$emit('input', false)"
          @save="save">
        <div class="my-2">Hier kann optional eine Beschreibung hinzugefügt werden:</div>
        <trumbowyg-editor v-if="modelHelper" v-model="relationships.content.description" :undo-button="false"/>
        <div class="mb-2 mt-4">Fügen Sie eine Paarung hinzu, indem Sie ein Wort eintippen und ein Bild auswählen und den
          Button "Paar hinzufügen" bestätigen.
        </div>
        <v-row>
          <v-col cols="8">
            <trumbowyg-editor v-if="modelHelper" v-model="pairing.text" :undo-button="false" small-config :max-character="50"/>
          </v-col>
          <v-col cols="4" class="d-flex flex-column align-center justify-center">
            <v-btn class="my-2" @click="openMediaPool = true">
              <v-icon v-if="!pairing.image_path" class="mr-1 p-0">add</v-icon>
              {{ pairing.image_path ? "Bild ändern" : "Bild auswählen" }}
            </v-btn>
            <v-img ref="image"
                   max-width="150"
                   max-height="150"
                   v-if="pairing.image_path"
                   :src="pairing.image_path"/>
          </v-col>
        </v-row>
        <v-btn :disabled="(pairing.image_path === null || pairing.text === null || pairing.text.length < 0)"
               class="mt-4" color="primary" @click="addPairing">
          Paar hinzufügen
        </v-btn>
        <p class="caption" v-if="pairing.image_path === null || pairing.text === null">Bitte Text eingeben und Bild auswählen.</p>
        <v-divider class="mb-2 mt-8"/>
        <div v-if="relationships.content.pairings.length > 0" class="text-lg-body-1 font-weight-bold mb-4">Paarungen:</div>
        <v-row v-for="(pg, index) of relationships.content.pairings" :key="`${pg}-${index}`"
               class="align-center">
          <v-col cols="auto">
            <v-btn icon @click="removePairing(pg)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <div class="no-padding" v-html="pg.text"/>
          </v-col>
          <v-col cols="4">
            <v-img ref="image"
                   max-width="50"
                   max-height="50"
                   v-if="pg.image_path"
                   :src="pg.image_path"/>
          </v-col>
        </v-row>
      </edit-content>
    </form>

    <v-dialog v-model="openMediaPool">
      <v-card>
        <v-card-title>Bitte wähle ein Logo</v-card-title>
        <mediapool :only-to-choose="true" mime-type="image" @choose="setImage"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import TrumbowygEditor from "../component/TrumbowygEditor";
import Mediapool from "../../media/Mediapool";

export default {
  components: {
    Mediapool,
    TrumbowygEditor,
    EditContent,
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      relationships: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          description: null,
          pairings: []
        },
        order: null,
      },
      openMediaPool: false,
      pairing: {
        text: null,
        image_path: null
      }
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    save() {
      this.$emit('save', this.relationships)
    },
    setImage(image) {
      this.openMediaPool = false
      this.pairing.image_path = image.path
    },
    addPairing() {
      this.relationships.content.pairings.push(JSON.parse(JSON.stringify(this.pairing)))
      this.pairing.text = null;
      this.pairing.image_path = null;
    },
    removePairing(pairing) {
      const index = this.relationships.content.pairings.findIndex(pair => pair.text === pairing.text && pair.image_path === pairing.image_path)
      if (index >= 0) {
        this.relationships.content.pairings.splice(index, 1);
      }
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.relationships = JSON.parse(JSON.stringify(this.content))
        this.relationships.css_id = this.relationships.css_id === null ? "relationship-" + Date.now() : this.relationships.css_id;
      }
    }
  }
}
</script>

<style scoped>
>>> .trumbowyg-box, >>> .trumbowyg-editor {
  min-height: 150px;
}

.no-padding >>> p {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-block-end: 0 !important;
}

</style>