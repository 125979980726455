<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700">
    <edit-content
        v-model="youtube.menu_title"
        :content-text="youtube.text + ' Video'"
        @close="$emit('input', false)"
        @save="$emit('save', youtube)"
        :disabled="!youtube.content.link || showError">
      <iframe v-if="videoId" width="100%" height="200" :src="'https://www.youtube-nocookie.com/embed/' + videoId"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen/>
      <v-text-field v-model="youtube.content.link" label="Link zum Video"
                    :error-messages="showError ? 'Bitte gebe eine gültige URL ein' : null"/>
      <v-text-field v-model.number="youtube.content.max_width" type="number" label="Maximale Breite in Pixel"/>
      <v-text-field v-model.number="youtube.content.max_height" type="number" label="Maximale Höhe in Pixel"/>
      <div class="subtitle-2" v-if="videoId">
        Ohne Angabe wird das Video automatisch skaliert
      </div>
    </edit-content>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";

export default {
  components: {
    EditContent
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      youtube: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          link: null,
          max_width: null,
          max_height: null
        },
        order: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    showError() {
      return this.videoId === null
    },
    /**
     * get video id from url
     * @returns {string|null}
     */
    videoId() {
      if (this.youtube.content.link) {
        try {
          const url = new URL(this.youtube.content.link)
          const id = url.searchParams.get("v")
          if (id) {
            return id
          }
        } catch (e) {
          console.error(e)
          return null
        }
      }
      return null
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.youtube = JSON.parse(JSON.stringify(this.content))
        this.content.css_id = this.content.css_id === null ? "youtube-" + Date.now() : this.content.css_id;
      }
    }
  }
}
</script>

<style scoped>

</style>