<template>
  <v-dialog v-model="modelHelper" max-width="700">
    <v-card>
      <v-card-title>Bitte wähle ein Medium aus</v-card-title>
      <v-card-text>
        <v-row v-for="(contentArray, idx) in contents"
               :key="idx"
               justify="center">
          <v-col
              v-for="content in contentArray"
              :key="content.text">
            <v-card
                class="pa-4"
                @click="onClick(content)"
                height="auto">
              <v-row no-gutters class="fill-height">
                <v-col align-self="center" class="text-center">
                  <v-icon color="primary" large>{{ content.icon }}</v-icon>
                  <v-spacer/>
                  {{ content.text }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
    },
    contentPosition: {
      required: true,
      type: Number
    },
    colPosition: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      contents: [
        [
          {
            text: 'Trennstrich',
            icon: 'horizontal_rule',
            mime_type: 'divider',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "divider-" + Date.now(),
            content: {
              thickness: 1
            },
            order: 0
          },
          {
            text: 'Textbereich',
            icon: 'article',
            mime_type: 'text',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "text-" + Date.now(),
            content: {
              text_content: ''
            },
            order: 0
          },
          {
            text: 'Bild',
            icon: 'image',
            mime_type: 'image',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "image-" + Date.now(),
            content: {
              path: '', name: '', max_width: null, max_height: null
            },
            order: 0
          },
        ],
        [
          {
            text: 'Audio',
            icon: 'volume_up',
            mime_type: 'audio',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "audio-" + Date.now(),
            content: {
              name: '', autoplay: false
            },
            order: 0
          },
          {
            text: 'Video',
            icon: 'movie',
            mime_type: 'video',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "video-" + Date.now(),
            content: {
              name: '', autoplay: false, max_width: null, max_height: null
            },
            order: 0
          },
          {
            text: 'Youtube',
            icon: 'smart_display',
            mime_type: 'youtube',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "youtube-" + Date.now(),
            content: {
              link: '', max_width: null, max_height: null
            },
            order: 0
          },
          {
            text: 'Zuordnung',
            icon: 'drag_indicator',
            mime_type: 'relationship',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "relationship-" + Date.now(),
            content: {
              description: null,
              pairings: []
            },
            order: 0
          },
        ],
        [
          {
            text: 'Quizfrage',
            icon: 'quiz',
            mime_type: 'quiz',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "quiz-" + Date.now(),
            content: {
              question: null, answers: [],
            },
            order: 0
          },
          {
            text: 'Zeichenfläche',
            icon: 'gesture',
            mime_type: 'canvas',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "canvas-" + Date.now(),
            content: {
              max_width: null,
              max_height: null,
              image_path: null,
              stroke_width: 5,
              stroke: "#000000"
            },
            order: 0
          },
          {
            text: 'Einbettung',
            icon: 'aspect_ratio',
            mime_type: 'embed',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "embed-" + Date.now(),
            content: {
              text: null,
            },
            order: 0
          },
          {
            text: 'Menü',
            icon: 'menu_book',
            mime_type: 'menu',
            menu_title: null,
            col: 1,
            col_width: null,
            css_id: "menu-" + Date.now(),
            content: {
              title: null,
            },
            order: 0
          }
        ]
      ]
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /**
     * add content to vuex store
     * @param content
     */
    onClick(content) {
      content.order = this.contentPosition
      content.col = this.colPosition
      content.key = new Date().getTime().toString()
      // is needed because component is only rendered once
      content.css_id = content.mime_type + "-" + Date.now();
      // Call by reference leads to problems, because otherwise all the same objects are changed at the same time
      this.$emit('selectContent', JSON.parse(JSON.stringify(content)))
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped>

</style>