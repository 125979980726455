<template>
  <v-card>
    <v-color-picker
        v-model="modelHelper"
        dot-size="25"
        show-swatches
        swatches-max-height="200"
    />
    <v-btn text class="ma-4" @click="$emit('input', defaultColor)">Farbe zurücksetzen</v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    defaultColor: {
      required: true,
      type: String
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style scoped>

</style>