<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-data-iterator
            :items="items"
            :loading="loadingMedia"
            :footer-props="footerProps"
            :sort-by="sortBy"
            :search="search"
            :sort-desc="sortBy === 'last_modified'"
            loading-text="Medien werden geladen"
            no-data-text="Keine Medien vorhanden"
            no-results-text="Keine Medien gefunden">

          <template v-slot:header>
            <v-toolbar class="mb-2 elevation-1">
              <v-toolbar-title v-if="!onlyToChoose || $vuetify.breakpoint.xs" class="me-4">Medien</v-toolbar-title>

              <v-row align="center" v-if="!$vuetify.breakpoint.xs">
                <v-toolbar-items>
                  <v-tabs hide-slider v-model="viewTab">
                    <v-tab>
                      <v-icon>view_list</v-icon>
                    </v-tab>
                    <v-tab>
                      <v-icon>grid_view</v-icon>
                    </v-tab>
                  </v-tabs>
                  <v-divider vertical/>
                </v-toolbar-items>
              </v-row>
              <v-select
                  v-if="!$vuetify.breakpoint.xs"
                  v-model="sortBy"
                  class="max-width me-2"
                  item-text="label"
                  item-value="key"
                  flat
                  solo-inverted
                  hide-details
                  :items="keys"
                  prepend-inner-icon="sort"
                  label="Sortieren nach"
              />

              <v-select
                  v-if="!$vuetify.breakpoint.xs && !onlyToChoose"
                  v-model="filterMimeType"
                  class="max-width me-2"
                  item-text="label"
                  item-value="key"
                  flat
                  solo-inverted
                  hide-details
                  :items="filter"
                  prepend-inner-icon="search"
                  label="Filtern nach"
              />

              <v-text-field
                  v-if="!$vuetify.breakpoint.xs"
                  v-model="search"
                  class="max-width me-2"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Suche"/>

              <v-spacer/>

              <v-btn v-if="!onlyToChoose" color="primary" @click="uploadDialog = true">
                Datei hinzufügen
              </v-btn>
              <v-progress-linear
                  :active="loadingMedia"
                  :indeterminate="loadingMedia"
                  absolute
                  bottom
              ></v-progress-linear>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-list v-if="viewTab === 0" two-line>
              <div v-for="item in props.items" :key="`${item.name}-list`">
                <v-hover v-slot="{ hover }">
                  <v-list-item @click="$emit('choose', item)">
                    <v-list-item-avatar>
                      <v-img v-if="item.mime_type.includes('image')" :src="item.path"/>
                      <v-icon v-if="item.mime_type.includes('audio')">volume_up</v-icon>
                      <video v-if="item.mime_type.includes('video')" width="80px" height="auto">
                        <source :src="item.path" :type="item.mime_type"/>
                      </video>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                          new Date(item.last_modified).toLocaleDateString()
                        }} ({{ (item.size / (1024 * 1024)).toFixed(2) }} MB)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="$vuetify.breakpoint.mobile && !onlyToChoose">
                      <v-btn icon @click="openDeleteDialog(item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <!-- Overlay to delete image-->
                    <v-overlay v-if="hover && !$vuetify.breakpoint.mobile && !onlyToChoose"
                               color="primary"
                               :opacity="0.1"
                               absolute>
                      <v-btn color="primary" large @click="openDeleteDialog(item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-list-item>
                </v-hover>
                <v-divider/>
              </div>

            </v-list>

            <v-row v-else>
              <v-col
                  cols="auto"
                  v-for="item in props.items"
                  :key="item.name">
                <v-hover v-slot="{ hover }">
                  <v-card
                      @click="$emit('choose', item)"
                      min-width="200"
                      max-width="200">

                    <!--                    MIMETYPE IMAGE-->
                    <v-img
                        v-if="item.mime_type.includes('image')"
                        height="150"
                        width="200"
                        :src="item.path"
                    ></v-img>

                    <!--                    MIMETYPE AUDIO-->
                    <v-card
                        v-if="item.mime_type.includes('audio')"
                        class="text-center justify-center"
                        height="150"
                        width="200"
                        flat>
                      <v-icon class="text-center fill-height" size="100">
                        volume_up
                      </v-icon>
                    </v-card>

                    <!--                    MIMETYPE VIDEO-->
                    <video v-if="item.mime_type.includes('video')" width="200" height="150">
                      <source :src="item.path" :type="item.mime_type"/>
                    </video>

                    <v-card-title class="subtitle-2">
                      {{ item.name }}
                    </v-card-title>
                    <v-card-subtitle class="caption">
                      {{ new Date(item.last_modified).toLocaleDateString() }}
                      ({{ (item.size / (1024 * 1024)).toFixed(2) }} MB)
                    </v-card-subtitle>

                    <v-card-actions v-if="$vuetify.breakpoint.mobile && !onlyToChoose" class="no-margin">
                      <v-spacer></v-spacer>

                      <v-btn icon @click="openDeleteDialog(item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <!-- Overlay to delete image-->
                    <v-overlay v-if="hover && !$vuetify.breakpoint.mobile && !onlyToChoose"
                               color="primary"
                               :opacity="0.1"
                               absolute>
                      <v-btn color="primary" large @click="openDeleteDialog(item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-overlay>

                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>

    <file-upload v-model="uploadDialog" :loading="loadingUpload" :progress="progress" @upload="upload"/>
    <notification v-model="showAlert" :text="alertText" :type="alertType"/>
    <confirmation-dialog v-model="deleteDialog" @confirm="deleteMedia"
                         :loading="loadingDelete"
                         message="Das Medium wird aus allen NFC Tags entfernt. Dies kann nicht rückgängig gemacht werden"
                         title="Willst du die Datei wirklich löschen?"/>
  </v-container>
</template>

<script>
import {NetworkMedia} from "../../helper/network/media/NetworkMedia";
import FileUpload from "./FileUpload";
import Notification from "../../components/Notification";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";

export default {
  components: {ConfirmationDialog, Notification, FileUpload},
  props: {
    onlyToChoose: {
      required: false,
      type: Boolean,
      default: false
    },
    mimeType: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      uploadDialog: false,
      loadingUpload: false,
      progress: null,
      loadingMedia: false,
      loadingDelete: false,
      items: [],
      search: '',

      chunks: [],
      uploaded: 0,
      file: null,
      uploadMimetype: null,

      alertText: "",
      alertType: null,
      showAlert: false,
      viewTab: 0,
      deleteDialog: false,
      itemToDelete: null,
      footerProps: {
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}'
      },
      sortBy: 'last_modified',
      filterMimeType: null,
      keys: [
        {label: 'Änderungsdatum', key: 'last_modified'},
        {label: 'Name', key: 'name'}
      ],
      filter: [
        {label: 'Alle', key: null},
        {label: 'Bilder', key: 'image'},
        {label: 'Videos', key: 'video'},
        {label: 'Audio', key: 'audio'},
      ]
    }
  },
  created() {
    this.fetchMedia()
  },
  methods: {
    /**
     * Get all media
     */
    async fetchMedia() {
      this.loadingMedia = true;
      const type = this.mimeType ? this.mimeType : this.filterMimeType
      try {
        let data = null;
        switch (type) {
          case 'image':
            data = await NetworkMedia.getImages()
            break;
          case 'audio':
            data = await NetworkMedia.getAudio()
            break;
          case 'video':
            data = await NetworkMedia.getVideos()
            break;
          default:
            data = await NetworkMedia.getMedia()
        }
        this.items = data.data;
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingMedia = false
      }
    },
    /**
     * Upload image to save in storage
     */
    async upload(file, mimeType) {
      this.file = file;
      this.loadingUpload = true;
      if (mimeType === "video") {
        await this.createChunks()
      } else {
        try {
          let formData = new FormData();
          formData.append('file', file)
          switch (mimeType) {
            case 'image':
              await NetworkMedia.saveImages(formData)
              this.showSuccessAlert("Bild erfolgreich gespeichert")
              break
            case 'audio':
              await NetworkMedia.saveAudio(formData)
              this.showSuccessAlert("Audio erfolgreich gespeichert")
              break
            default:
              console.error("No such mime type")
          }
          await this.fetchMedia()
        } catch (e) {
          console.error(e)
          this.showErrorAlert("Datei konnte nicht gespeichert werden")
        } finally {
          this.loadingUpload = false;
          this.uploadDialog = false;
        }
      }
    },
    async createChunks() {
      // 4 MB chunks
      this.chunks = [];
      let size = 4194304, chunks = Math.ceil(this.file.size / size);

      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
            i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
      // show progress circle
      let oneProgress = 100 / this.chunks.length
      this.progress = 0;
      try {
        // upload every chunk
        for (let i = 0; i < this.chunks.length; i++) {
          let chunk = this.chunks[i];
          await this.uploadFile(chunk, i === this.chunks.length - 1, i === 0)
          this.progress += oneProgress;
        }
        this.showSuccessAlert("Video erfolgreich gespeichert")
        await this.fetchMedia()
      } catch (e) {
        console.error(e)
        this.showErrorAlert("Datei konnte nicht gespeichert werden")
      } finally {
        this.progress = null;
        this.loadingUpload = false;
        this.uploadDialog = false;
      }
    },
    /**
     * Send chunks. is_first and is_last, needed to delete all chunks before (is_first) or to merge all chunks (is_last).
     */
    async uploadFile(chunk, isLast, isFirst) {
      let formData = new FormData;
      formData.set('is_first', isFirst);
      formData.set('is_last', isLast);
      formData.set('file', chunk, this.file.name);
      await NetworkMedia.saveVideo(formData)
    },
    /**
     * delete image
     */
    async deleteMedia() {
      this.loadingDelete = true
      try {
        const mimeType = this.itemToDelete.mime_type.split("/")
        switch (mimeType[0]) {
          case "image":
            await NetworkMedia.deleteImage(this.itemToDelete.name)
            this.showSuccessAlert("Bild erfolgreich gelöscht")
            break
          case "audio":
            await NetworkMedia.deleteAudio(this.itemToDelete.name)
            this.showSuccessAlert("Audio erfolgreich gelöscht")
            break;
          case "video":
            await NetworkMedia.deleteVideo(this.itemToDelete.name)
            this.showSuccessAlert("Video erfolgreich gelöscht")
            break;
          default:
            console.error("No such mime type")
        }
        await this.fetchMedia()
      } catch (e) {
        console.error(e)
        this.showErrorAlert("Datei konnte nicht gelöscht werden")
      } finally {
        this.deleteDialog = false
        this.loadingDelete = false;
        this.itemToDelete = null;
      }
    },
    /**
     * open confirmation dialog
     */
    openDeleteDialog(item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },
    /**
     * show success alert
     * @param message
     */
    showSuccessAlert(message) {
      this.alertText = message
      this.alertType = "success"
      this.showAlert = true;
    },
    /**
     * show error alert
     * @param message
     */
    showErrorAlert(message) {
      this.alertText = message
      this.alertType = "error"
      this.showAlert = true;
    },
  }
  ,
  watch: {
    mimeType() {
      this.fetchMedia()
    }
    ,
    filterMimeType() {
      this.fetchMedia()
    }
  }
}
</script>

<style scoped>
.no-margin {
  padding: 0 !important;
  margin: 0 !important;
}

.max-width {
  max-width: 250px !important;
}

</style>