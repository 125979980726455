<template>
  <div>
    <div v-html="nfcContent.content.description"/>
    <v-row no-gutters>
      <v-col cols="6">
        <v-col v-for="(text, index) of texts" :key="text" cols="12" class="no-padding">
          <div :style="{'height': ($vuetify.breakpoint.xs || mobilePreview) ? '100px' : '150px'}"
               class="default-font d-flex align-center" :class="{opacity: !images[index].draggable}" v-html="text"/>
          <v-divider v-if="index < texts.length - 1" class="my-1"/>
        </v-col>
      </v-col>
      <v-col cols="6">
        <draggable
            v-model="images"
            v-bind="dragOptions"
            @start="startDrag"
            class="sortable-list"
            :move="handleMove"
            handle=".draggable"
            @end="endDrag">

          <v-col v-for="(image, index) of images" :key="image.image_path" cols="12"
                 :class="{draggable: image.draggable}" class="no-padding">
            <div class="d-flex justify-center" :class="{opacity: !image.draggable}">
              <v-img :height="$vuetify.breakpoint.xs || mobilePreview ? 100 : 150"
                     :max-width="$vuetify.breakpoint.xs || mobilePreview ? 100 : 150"
                     :class="{grabbable: image.draggable}"
                     contain
                     :src="image.image_path"/>
              <v-icon v-if="image.draggable" class="ms-2 xxlarge grabbable">drag_indicator</v-icon>
              <v-icon v-else color="green" class="ms-2 xxlarge">done</v-icon>
            </div>
            <v-divider v-if="index < images.length - 1" class="my-1"/>
          </v-col>

        </draggable>
      </v-col>
    </v-row>

    <v-row class="align-center">
      <v-col cols="auto">
        <v-btn :color="color" :style="{color: getContrastColor(color)}" @click="checkSolution"
               :disabled="solutions >= images.length">Lösung prüfen
        </v-btn>
      </v-col>
      <v-col>
        <div v-if="solutions > 0" class="green--text text-body-1">
          <span class="font-weight-bold">{{ solutions }}</span> von <span
            class="font-weight-bold">{{ images.length }}</span> Zuordnungen richtig gelöst.
          {{ solutions >= images.length ? "Perfekt!" : "" }}
        </div>
        <div v-if="allWrong" class="red--text text-body-1">
          Leider keine richtige Antwort.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {getContrastColor} from "../../../helper/ColorHelper";

export default {
  components: {
    draggable
  },
  props: {
    nfcContent: {
      required: true
    },
    mobilePreview: {
      required: false,
      type: Boolean,
      default: false
    },
    color: {
      required: true
    }
  },
  data() {
    return {
      images: [],
      texts: [],
      movingIndex: null,
      futureIndex: null,
      getContrastColor,
      allWrong: false,
    }
  },
  created() {
    this.createRandomPairings()
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
    /**
     * return length of right relationships
     */
    solutions() {
      return this.images.filter(image => image.draggable === false).length
    },
  },
  watch: {
    nfcContent() {
      this.createRandomPairings()
    }
  },
  methods: {
    /**
     * create images and texts arrays and shuffle those content
     */
    createRandomPairings() {
      this.images = this.nfcContent.content.pairings.map(pairing => {
        return {
          image_path: pairing.image_path,
          draggable: true
        }
      })
      this.shuffle(this.images);
      this.texts = this.nfcContent.content.pairings.map(pairing => pairing.text)
      this.shuffle(this.texts);
    },
    /**
     * check if users solutions are right
     */
    checkSolution() {
      for (let i = 0; i < this.nfcContent.content.pairings.length; i++) {
        // check solution from user
        if (this.nfcContent.content.pairings.find(pairing => pairing.image_path === this.images[i].image_path && pairing.text === this.texts[i])) {
          // right solution can not be dragged anymore
          this.images[i].draggable = false
        }
      }
      if (this.solutions <= 0) {
        this.allWrong = true;
        return;
      }
      this.allWrong = false
    },
    shuffle(array) {
      let currentIndex = array.length, randomIndex;
      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    },
    startDrag(event) {
      this.drag = true
      this.lastScrollPosition = event.originalEvent.clientY
    },
    endDrag() {
      this.drag = false
      // false draggable can not be dragged
      if (!this.images[this.futureIndex].draggable) {
        return false;
      }
      this.futureItem = this.images[this.futureIndex];
      this.movingItem = this.images[this.movingIndex];
      const _items = Object.assign([], this.images);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;

      this.images = _items;
    },
    handleMove(e) {
      const {index, futureIndex} = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      return false; // disable sort
    }
  }
}
</script>

<style scoped>
.ghost div {
  opacity: 0.7;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.default-font {
  font-size: 18px;
  font-family: Arial, serif;
  font-weight: bold;
}

.xxlarge {
  font-size: 48px;
}

.no-padding {
  padding: 0 !important;
}

.no-padding >>> p {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-block-end: 0 !important;
}

.opacity {
  opacity: 0.5;
}

</style>