var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.title))])]),(_vm.actionButtonText)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('clickBtn')}}},[_vm._v(_vm._s(_vm.actionButtonText))])],1):_vm._e()],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":_vm.loadingText,"server-items-length":_vm.total,"options":_vm.modelHelper,"sort-by":"created_at","sort-desc":true,"items-per-page":10,"no-data-text":"Keine Daten vorhanden","no-results-text":"Keine Einträge gefunden","header-props":_vm.headerProps,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.modelHelper=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._t("created_at",null,{"item":item})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("actions",null,{"item":item})]}},{key:"item.copy",fn:function(ref){
var item = ref.item;
return [_vm._t("copy",null,{"item":item})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_vm._t("preview",null,{"item":item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }