var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('data-table',{staticClass:"row-pointer",attrs:{"title":"NFC Tags","headers":_vm.computedHeaders,"items":_vm.tags,"loading":_vm.loading,"total":_vm.totalTags,"loading-text":"NFC Tags werden geladen"},on:{"clickRow":_vm.handleClickOnRow},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openConfirmDialog(item)}}},[_vm._v(" delete ")])]}},{key:"copy",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"lower-case",attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.copyLink(item.url)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("content_copy")]),_vm._v(" "+_vm._s(item.url)+" ")],1)]}},{key:"preview",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","target":"_blank","href":item.url},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("open_in_new")])],1)]}}]),model:{value:(_vm.options),callback:function ($$v) {_vm.options=$$v},expression:"options"}}),_c('v-card',{staticClass:"mt-8"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createTag()}}},[_c('v-card-title',[_vm._v("NFC Tag erstellen")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.tag.name),callback:function ($$v) {_vm.$set(_vm.tag, "name", $$v)},expression:"tag.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Beschreibung"},model:{value:(_vm.tag.description),callback:function ($$v) {_vm.$set(_vm.tag, "description", $$v)},expression:"tag.description"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loadingCreateBtn},on:{"click":function($event){return _vm.reset()}}},[_vm._v("Verwerfen")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","type":"submit","loading":_vm.loadingCreateBtn}},[_vm._v("Erstellen ")])],1)],1)],1),_c('confirmation-dialog',{attrs:{"loading":_vm.loadingDeleteBtn,"title":"NFC Tag wirklich löschen?"},on:{"confirm":function($event){return _vm.deleteNfcTag()}},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}}),_c('notification',{attrs:{"text":_vm.alertText,"type":_vm.alertType},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }