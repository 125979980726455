<template>
  <v-btn-toggle
      v-model="modelHelper"
      color="primary"
      mandatory>
    <v-btn small value="start">
      <v-icon>format_align_left</v-icon>
    </v-btn>
    <v-btn small value="center">
      <v-icon>format_align_center</v-icon>
    </v-btn>
    <v-btn small value="end">
      <v-icon>format_align_right</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit("input", newValue)
      }
    }
  }

}
</script>

<style scoped>

</style>