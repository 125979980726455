<template>
  <v-card>
    <v-card-title>
      {{ contentText }} bearbeiten
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <v-row align="center" no-gutters>
        <v-col cols="auto">
          <v-checkbox
              v-if="!disableMenuCheckbox"
              v-model="activateSection"
              :label="activateSection ? null : 'Bereich in das Menü aufnehmen'"/>
        </v-col>
        <v-col cols="auto"
            v-if="activateSection">
          <v-text-field
              v-model="modelHelper"
              outlined
              hide-details
              dense
              label="Menütitel"/>
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-btn @click="$emit('close')" class="ma-2">Abbrechen</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="emitSave" :disabled="disabled || noTitle" color="primary" class="ma-2">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    // section title
    value: {
      type: String,
      default: null,
    },
    disableMenuCheckbox: {
      default: false,
      required: false,
      type: Boolean
    },
    contentText: {
      required: true,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activateSection: false,
    }
  },
  created() {
    this.activateSection = this.value !== null && this.value.length > 0
  },
  watch: {
    value() {
      this.activateSection = this.value !== null;
    }
  },
  computed: {
    noTitle() {
      return this.activateSection && (this.value === null || this.value.length <= 0);
    },
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    emitSave() {
      // if checkbox is deactivated, than menu_title is null
      if (!this.activateSection) {
        this.$emit('input', null)
      }
      this.$emit('save')
    }
  }
}
</script>

<style scoped>
.v-card__text {
  color: #000000 !important;
}
</style>