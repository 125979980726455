var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-card-title',[_c('h2',{staticClass:"text-uppercase"},[_vm._v("Dein Profil "),(!_vm.edit)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.edit = true}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1):_vm._e()],1)]),_c('v-card-text',[(!_vm.edit)?[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.user.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_vm._v("E-Mail:")]),(_vm.user.street || _vm.user.postalcode || _vm.user.city || _vm.user.country)?_c('div',[_vm._v(" Adresse: ")]):_vm._e()]),_c('v-col',[_c('div',[_vm._v(_vm._s(_vm.user.email))]),_c('div',[_vm._v(_vm._s(_vm.user.street))]),_c('div',[_vm._v(_vm._s(_vm.user.postalcode)+" "+_vm._s(_vm.user.city))]),_c('div',[_vm._v(_vm._s(_vm.user.country))])])],1)]:_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Name ")]},proxy:true}],null,true),model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"E-Mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("E-Mail ")]},proxy:true}],null,true),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',[_c('user-fields',{model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"confirmed:password","mode":"aggressive","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Neues Passwort"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password","mode":"aggressive","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Neues Passwort wiederholen"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)],1)],2),(_vm.edit)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loadingDialog},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"loading":_vm.loadingDialog},on:{"click":function($event){return _vm.updateProfile()}}},[_vm._v(" Speichern ")])],1):_vm._e()],1)]}}])})],1),_c('notification',{attrs:{"type":_vm.alertType,"text":_vm.alertText},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }