<template>
  <v-container>
    <template v-if="loadingData">
      <v-skeleton-loader type="actions, card-heading, image" :loading="true"/>
    </template>

    <!--    Slug could not be read-->
    <template v-else-if="error">
      <v-card>
        <v-card-title>
          Der NFC-Tag konnte nicht geladen werden.
        </v-card-title>
        <v-card-text>
          Bitte überprüfe die Internetverbindung und versuche es erneut.
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-card class="mb-2">
        <v-card-title>
          NFC Tag bearbeiten
          <v-spacer/>
          <v-btn v-if="!isLink" @click="preview = !preview"
                 :disabled="loadingSave"
                 color="primary" class="me-2 mb-2" text>
            {{ preview ? "Bearbeitung" : "Vorschau" }}
          </v-btn>
          <v-btn @click="close()" :disabled="loadingSave" class="me-2 mb-2">Zurück</v-btn>
          <v-btn @click="save()" :loading="loadingSave" color="primary" class="mb-2">Speichern</v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-row>
            <v-col>
              Hier kannst du den NFC Tag bearbeiten bzw. neue Inhalte hinzufügen
            </v-col>
            <v-col cols="auto">
              <v-switch v-model="autoSaveEnabled" :loading="loadingAutoSave" dense hide-details class="mt-0"
                        label="Automatisches Speichern"/>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>

      <!--Logo, BG, etc. card -->
      <v-card class="justify-center mb-2" v-if="!preview">
        <v-card-text>
          <v-row align="center">
            <v-col v-if="nfcTag.logo" cols="auto">
              <alignment-buttons v-model="alignmentLogo"/>
            </v-col>
            <v-col v-if="!nfcTag.logo" cols="auto">
              <v-btn @click="openMediaPool = true">
                Logo
              </v-btn>
            </v-col>
            <v-col v-else cols="auto">
              <img
                  class="logo"
                  :src="nfcTag.logo"
                  alt="Logo"/>
            </v-col>
            <v-col v-if="nfcTag.logo" cols="auto">
              <v-btn small icon @click="deleteLogo">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
              <v-btn :color="backgroundColor" :style="{color: getContrastColor(backgroundColor)}"
                     @click="backgroundColorDialog = true">Hintergrundfarbe
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn :color="barColor" :style="{color: getContrastColor(barColor)}"
                     @click="barColorDialog = true">Menü Farbe
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" :color="accentColor" :style="{color: getContrastColor(accentColor)}"
                         @click="accentColorDialog = true">Akzentfarbe
                  </v-btn>
                </template>
                <span>Wirkt sich z.B. auf das Inhaltsverzeichnis aus</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- general settings card -->
      <v-card class="mb-2" v-if="!preview">
        <v-card-text>
          <v-row>
            <v-spacer/>
            <v-col cols="auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" @click="openCssDialog = true" text color="primary">
                    <v-icon large>css</v-icon>
                  </v-btn>
                </template>
                <span>Seite mit Stylesheet-Sprache gestalten</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog v-model="openCssDialog" persistent>
        <edit-css v-model="nfcTag.css" @close="openCssDialog = false"/>
      </v-dialog>

      <!-- Name and description card -->
      <v-card class="mb-8" v-if="!preview">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="nfcTag.name" label="Name"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="nfcTag.description" label="Beschreibung"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Contents -->
      <div v-if="!preview">
        <!-- Add the first content-->
        <div class="text-center" v-if="contents.length === 0">
          Bitte benutze das
          <v-icon color="primary">add</v-icon>
          um neue Inhalte hinzuzufügen oder
          <v-icon color="primary">link</v-icon>
          um den Nutzer auf eine andere URL weiterzuleiten
        </div>

        <draggable
            v-model="contents"
            v-bind="dragOptions"
            :move="handleScroll"
            @start="startDrag"
            class="sortable-list"
            @end="endDrag">

          <v-row v-for="content in contents"
                 :key="content.index"
                 class="sortable no-gutters">

            <v-card color="cardBackground"
                    width="100%"
                    :class="{distances: content.items.length > 0}">

              <draggable
                  :list="content.items"
                  :group="{ name: 'content' }"
                  class="row wrap no-gutters">

                <v-col v-for="item in content.items"
                       :key="item.css_id"
                       :cols="item.col_width"
                       class="mx-2">

                  <!-- Code snippet to edit column width-->
                  <!-- <v-btn v-if="content.items.length > 1 && idx === 0"-->
                  <!-- small-->
                  <!-- @click="openEditColumnsDialog(content.index)">-->
                  <!-- <v-icon class="me-1">edit</v-icon>-->
                  <!-- {{ content.items.length }} Spalten-->
                  <!-- </v-btn>-->
                  <!-- <div v-else-if="content.items.length > 1" class="small-btn-placeholder"/>-->
                  <Contents :content="item"
                            :id="item.css_id"
                            :key="`content-${item.css_id}`"
                            @delete="deleteContent(item)"
                            @copy="copyContent(item)"
                            @edit="editContent(item)"
                            :accent-color="accentColor !== null ? accentColor : this.$vuetify.theme.themes.light.primary"
                            @add="openSelectionDialog(content.index)"/>
                </v-col>
              </draggable>

              <!--Insert new content in new row-->
              <v-card-actions v-if="content.items.length > 0">
                <v-row no-gutters>
                  <v-col align="center">
                    <v-btn
                        v-if="!isLink"
                        color="primary"
                        icon small
                        @click="openSelectionDialog(content.index + 1, 1)">
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>

            </v-card>
          </v-row>
        </draggable>

        <v-card-actions v-if="!preview && contents.length <= 0" class="justify-center">
          <!--Insert new content in new row-->
          <v-tooltip :disabled="!isLink" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                    color="primary"
                    :disabled="isLink"
                    fab icon large
                    @click="openSelectionDialog(contents.length + 1, 1)">
                  <v-icon>
                    add
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>Es können entweder Inhalte oder ein Link hinzugefügt werden</span>
          </v-tooltip>
          <v-btn v-if="contents.length <= 0"
                 color="primary"
                 fab icon large
                 @click="createLink()">
            <v-icon>
              link
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>

      <content-selection v-model="selectionDialog" :content-position="contentPosition" :col-position="colPosition"
                         @selectContent="editContent"/>

      <!-- Dialog to edit column width-->
      <edit-columns v-model="editColumnsDialog" :index-to-edit="rowIdxToEdit"/>

      <!-- Dialogues to edit the respective contents -->
      <edit-text-content
          v-model="editTextDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-divider-content
          v-model="editDividerDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-image-content
          v-model="editImageDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-audio-content
          v-model="editAudioDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-youtube-content
          v-model="editYoutubeDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-relationships-content
          v-model="editRelationshipsDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-canvas-content
          v-model="editCanvasDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-quiz-content
          v-model="editQuizDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-link-content
          v-model="editLinkDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-video-content
          v-model="editVideoDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-embed-content
          v-model="editEmbedDialog"
          :content="contentToEdit"
          @save="updateContent"/>
      <edit-menu-content
          v-model="editMenuDialog"
          :content="contentToEdit"
          @save="updateContent"/>

      <v-dialog v-model="openMediaPool">
        <v-card>
          <v-card-title>Bitte wähle ein Logo</v-card-title>
          <mediapool :only-to-choose="true" mime-type="image" @choose="setLogo"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="openMediaPool = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="backgroundColorDialog" max-width="300">
        <color-picker v-model="backgroundColor" default-color="#f5f5f5"/>
      </v-dialog>

      <v-dialog v-model="barColorDialog" max-width="300">
        <color-picker v-model="barColor" :default-color="$vuetify.theme.themes.light.primary"/>
      </v-dialog>

      <v-dialog v-model="accentColorDialog" max-width="300">
        <color-picker v-model="accentColor" :default-color="$vuetify.theme.themes.light.primary"/>
      </v-dialog>
    </template>
    <notification v-model="showAlert" :type="alertType" :text="alertText"/>
    <confirmation-dialog v-model="showConfirmDialog" title="Nicht gespeicherte Änderungen" action-btn-name="Ja"
                         message="Es sind nicht gespeicherte Änderungen vorhanden. Möchtest du wirklich abbrechen?"
                         @confirm="close(true)"/>

    <!-- PREVIEW -->
    <template v-if="preview">
      <v-card class="my-4 pa-4" :color="toggleMobile ? '#ededed' : null">
        <v-btn-toggle v-model="toggleMobile" tile
                      color="primary"
                      class="center-view"
                      group>
          <v-btn :value="false">
            Desktop
            <v-icon class="ms-1">desktop_windows</v-icon>
          </v-btn>
          <v-btn :value="true">
            Mobil
            <v-icon class="ms-1">smartphone</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-divider/>
        <div :class="{'center-view': toggleMobile}">
          <v-card :color="backgroundColor" id="customer-content-card" flat :width="toggleMobile ? '411px' : null">
            <v-row v-for="content in contents"
                   :key="content.index"
                   no-gutters>
              <v-col v-for="item in content.items"
                     :key="item.css_id"
                     :cols="item.col_width">
                <customer-content :id="item.css_id" :nfc-content="item"
                                  :accent-color="accentColor !== null ? accentColor : this.$vuetify.theme.themes.light.primary"
                                  :mobile-preview="toggleMobile"
                                  :card-width="toggleMobile ? '411px' : null"/>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card>
    </template>
    <scroll-to-top :color="accentColor"/>
  </v-container>
</template>

<script>
import {getContrastColor} from '../../helper/ColorHelper'
import draggable from 'vuedraggable'
import ContentSelection from "./dialog/ContentSelection";
import EditTextContent from "./dialog/EditTextContent";
import EditDividerContent from "./dialog/EditDividerContent";
import {NetworkTag} from "../../helper/network/tag/NetworkTag";
import Notification from "../../components/Notification";
import EditImageContent from "./dialog/EditImageContent";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import EditAudioContent from "./dialog/EditAudioContent";
import EditYoutubeContent from "./dialog/EditYoutubeContent";
import EditCanvasContent from "./dialog/EditCanvasContent";
import EditQuizContent from "./dialog/EditQuizContent";
import EditLinkContent from "./dialog/EditLinkContent";
import EditVideoContent from "./dialog/EditVideoContent";
import Contents from "./contents/Contents";
import EditEmbedContent from "./dialog/EditEmbedContent";
import Mediapool from "../media/Mediapool";
import ScrollToTop from "../../components/ScrollToTop";
import AlignmentButtons from "../../components/button/AlignmentButtons";
import CustomerContent from "../customer/component/CustomerContent";
import EditColumns from "../../components/dialog/EditColumns";
import ColorPicker from "../../components/ColorPicker";
import EditCss from "./dialog/EditCss";
import EditMenuContent from "./dialog/EditMenuContent";
import EditRelationshipsContent from "./dialog/EditRelationshipsContent";

export default {
  components: {
    EditRelationshipsContent,
    EditMenuContent,
    EditCss,
    ColorPicker,
    EditColumns,
    AlignmentButtons,
    CustomerContent,
    ScrollToTop,
    Mediapool,
    EditEmbedContent,
    Contents,
    EditVideoContent,
    EditLinkContent,
    EditQuizContent,
    EditCanvasContent,
    EditYoutubeContent,
    EditAudioContent,
    ConfirmationDialog,
    EditImageContent,
    Notification,
    EditDividerContent,
    EditTextContent,
    ContentSelection,
    draggable
  },
  data() {
    return {
      selectionDialog: false,
      drag: false,
      contentToEdit: null,
      contentPosition: 0,
      colPosition: 1,
      toggleMobile: false,

      editTextDialog: false,
      editDividerDialog: false,
      editImageDialog: false,
      editAudioDialog: false,
      editYoutubeDialog: false,
      editCanvasDialog: false,
      editQuizDialog: false,
      editRelationshipsDialog: false,
      editLinkDialog: false,
      editVideoDialog: false,
      editEmbedDialog: false,
      editMenuDialog: false,
      editColumnsDialog: false,

      openCssDialog: false,

      rowIdxToEdit: null,

      loadingData: false,
      loadingSave: false,
      loadingAutoSave: false,
      autoSaveEnabled: false,
      autoSaveInterval: null,
      nfcTag: {
        name: null,
        logo: null,
        alignment_logo: null,
        background_color: null,
        bar_color: null,
        accent_color: null,
        css: null,
        description: null,
        nfc_contents: []
      },
      alertText: "",
      alertType: null,
      error: false,
      // save first time with automatisches Speichern
      saveFirstTime: false,
      showAlert: false,
      showConfirmDialog: false,
      changesWithoutSave: false,
      preview: false,
      lastScrollPosition: 0,
      openMediaPool: false,
      backgroundColorDialog: false,
      barColorDialog: false,
      accentColorDialog: false,
      backgroundColor: "#FFFFFF",
      barColor: "#FFFFFF",
      accentColor: "#FFFFFF",
      alignmentLogo: "start",
      getContrastColor,
    }
  },
  computed: {
    contents: {
      get() {
        return this.$store.state.content.nfcContents
      },
      set(newValue) {
        this.$store.commit("content/setContentsOneToOne", newValue)
      }
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
    // is mimetype === link?
    isLink() {
      for (const content of this.contents) {
        for (const item of content.items) {
          if (item.mime_type === 'link') {
            return true
          }
        }
      }
      return false
    },
  },
  created() {
    this.fetchNfcTag()
    // check if autosave is enabled or not
    this.autoSaveEnabled = localStorage.getItem('autosave') === 'true'
    this.saveFirstTime = this.autoSaveEnabled
  },
  watch: {
    autoSaveEnabled(n) {
      localStorage.setItem('autosave', n)
      this.setSaveInterval(n)
      this.saveFirstTime = n;
    },
    barColor(value) {
      this.$store.commit("helper/setBarColor", value)
      this.changesWithoutSave = true
    },
    alignmentLogo(value) {
      this.$store.commit("helper/setAlignmentLogo", value)
      this.changesWithoutSave = true
    }
  },
  destroyed() {
    // clear auto save intervall !IMPORTANT!
    clearInterval(this.autoSaveInterval)
    // clear vuex store
    this.$store.commit("content/setContents", [])
  },
  methods: {
    /**
     * Get content with specific order
     */
    contentsLengthWithOrder(order) {
      const contentWithOrder = this.contents.find(content => content.index === order)
      return contentWithOrder.items.length
    },
    /**
     * Open dialog to edit manually column width
     */
    // openEditColumnsDialog(rowIdxToEdit) {
    //   this.rowIdxToEdit = rowIdxToEdit
    //   this.editColumnsDialog = true
    // },

    // save content every 30 seconds
    setSaveInterval(bool) {
      if (bool) {
        this.autoSaveInterval = setInterval(() => {
          this.save(true)
        }, 30000)
      } else {
        // clear auto save intervall !IMPORTANT!
        clearInterval(this.autoSaveInterval)
      }
    },
    /**
     * remove Logo
     */
    deleteLogo() {
      this.nfcTag.logo = null
      this.$store.commit("content/removeLogo")
      this.changesWithoutSave = true
    },
    /**
     * Set logo in app bar
     */
    setLogo(logo) {
      this.openMediaPool = false
      this.nfcTag.logo = logo.path
      this.$store.commit("content/setLogo", logo.path)
      this.changesWithoutSave = true
    },
    /**
     * remove content from vuex store
     */
    deleteContent(content) {
      this.$store.commit('content/removeContent', content)
      this.changesWithoutSave = true
    },
    /**
     * duplicate content in vuex store
     */
    copyContent(content) {
      const newContent = JSON.parse(JSON.stringify(content))
      newContent.css_id = newContent.mime_type + "-" + Date.now();
      newContent.order = this.contents.length
      newContent.key = new Date().getTime().toString()
      this.$store.commit('content/addContent', newContent)
      this.changesWithoutSave = true
    },
    /**
     * Open dialog to choose content
     */
    openSelectionDialog(pastePosition, columnPostion) {
      this.contentPosition = pastePosition
      if (columnPostion === undefined || columnPostion === null) {
        // if no column position is given, compute the last position
        this.colPosition = this.contentsLengthWithOrder(pastePosition) + 1
      } else {
        this.colPosition = columnPostion
      }
      this.selectionDialog = true
    },
    /**
     * Open edit dialog for a specific mime type
     * @param content
     */
    editContent(content) {
      this.contentToEdit = content
      switch (content.mime_type) {
        case 'text':
          this.editTextDialog = true
          break
        case 'divider':
          this.editDividerDialog = true
          break
        case 'image':
          this.editImageDialog = true
          break
        case 'audio':
          this.editAudioDialog = true
          break
        case 'youtube':
          this.editYoutubeDialog = true
          break
        case 'canvas':
          this.editCanvasDialog = true
          break
        case 'quiz':
          this.editQuizDialog = true
          break;
        case 'relationship':
          this.editRelationshipsDialog = true
          break;
        case 'link':
          this.editLinkDialog = true
          break;
        case 'video':
          this.editVideoDialog = true
          break;
        case 'embed':
          this.editEmbedDialog = true
          break;
        case 'menu':
          this.editMenuDialog = true
          break;
      }
    },
    /**
     * update content in vuex store and close all dialogs
     */
    updateContent(content) {
      this.contentToEdit = content
      this.$store.commit('content/updateContent', this.contentToEdit)
      this.editTextDialog = false
      this.editDividerDialog = false
      this.editImageDialog = false
      this.editAudioDialog = false
      this.editYoutubeDialog = false
      this.editCanvasDialog = false
      this.editQuizDialog = false
      this.editRelationshipsDialog = false
      this.editLinkDialog = false
      this.editVideoDialog = false
      this.editEmbedDialog = false
      this.editMenuDialog = false
      this.changesWithoutSave = true
    },
    async fetchNfcTag() {
      this.loadingData = true;
      try {
        // get nfc tag and save it to the vuex store
        const {data} = await NetworkTag.getTag(this.$route.query.id)
        this.nfcTag = data.data
        this.$store.commit("content/setLogo", this.nfcTag.logo)
        this.$store.commit("helper/setBarColor", this.nfcTag.bar_color)
        this.$store.commit("helper/setAlignmentLogo", this.nfcTag.alignment_logo)
        this.backgroundColor = this.nfcTag.background_color !== null ? this.nfcTag.background_color : "#f5f5f5"
        this.barColor = this.nfcTag.bar_color !== null ? this.nfcTag.bar_color : this.$vuetify.theme.themes.light.primary
        this.accentColor = this.nfcTag.accent_color !== null ? this.nfcTag.accent_color : this.$vuetify.theme.themes.light.primary
        this.alignmentLogo = this.nfcTag.alignment_logo
        await this.saveContent()
        this.error = false;
      } catch (e) {
        console.error(e)
        this.error = true;
      } finally {
        this.loadingData = false
      }
    },
    /**
     * return only inner contents in array
     */
    getContents() {
      const cleanContents = [];
      for (let i = 0; i < this.contents.length; i++) {
        let content = this.contents[i]
        for (let j = 0; j < content.items.length; j++) {
          let item = content.items[j];
          item.order = i;
          item.col = j + 1;
          cleanContents.push(item)
        }
      }
      return cleanContents;
    },
    /**
     * save content in database and go back to nfc tag overview page
     */
    async save(autoSave = false) {
      if (autoSave) {
        this.loadingAutoSave = true;
      }
      this.loadingSave = true;
      try {
        this.nfcTag.nfc_contents = this.getContents()
        this.nfcTag.background_color = this.backgroundColor
        this.nfcTag.bar_color = this.barColor
        this.nfcTag.accent_color = this.accentColor
        this.nfcTag.alignment_logo = this.alignmentLogo
        await NetworkTag.changeNfcTag(this.nfcTag)
        if (!autoSave) {
          this.showSuccessAlert("NFC Tag erfolgreich gespeichert")
        }
        if (autoSave && this.saveFirstTime) {
          this.showSuccessAlert("Automatisches Speichern aktiv")
          this.saveFirstTime = false;
        }
        this.changesWithoutSave = false
      } catch (e) {
        console.error(e)
        if (autoSave) {
          this.showErrorAlert("NFC Tag konnte nicht gespeichert werden. Automatisches speichern wurde deaktiviert")
          this.autoSaveEnabled = false;
        } else {
          this.showErrorAlert("NFC Tag konnte nicht gespeichert werden")
        }
      } finally {
        this.loadingSave = false
        this.loadingAutoSave = false
      }
    },
    /**
     * redirect to nfc tags overview page
     */
    async close(shouldBeClosed = false) {
      if (this.changesWithoutSave && !shouldBeClosed) {
        this.showConfirmDialog = true
        return
      }
      await this.$router.push("/nfc/tags")
    },
    /**
     * save contents in vuex store
     */
    saveContent() {
      this.$store.commit("content/setContents", [])
      for (let nfcContents of this.nfcTag.nfc_contents) {
        switch (nfcContents.mime_type) {
          case "text":
            nfcContents.text = "Textbereich"
            nfcContents.icon = "article"
            break;
          case "divider":
            nfcContents.text = "Trennstrich"
            nfcContents.icon = "horizontal_rule"
            break;
          case "image":
            nfcContents.text = "Bild"
            nfcContents.icon = "image"
            break;
          case "audio":
            nfcContents.text = "Audio"
            nfcContents.icon = "volume_up"
            break;
          case "youtube":
            nfcContents.text = "Youtube"
            nfcContents.icon = "smart_display"
            break;
          case "canvas":
            nfcContents.text = "Zeichenfläche"
            nfcContents.icon = "gesture"
            break;
          case "quiz":
            nfcContents.text = "Quizfrage"
            nfcContents.icon = "quiz"
            break;
          case "relationship":
            nfcContents.text = "Zuordnung"
            nfcContents.icon = "drag_indicator"
            break;
          case "link":
            nfcContents.text = "Link"
            nfcContents.icon = "link"
            break;
          case "video":
            nfcContents.text = "Video"
            nfcContents.icon = "movie"
            break;
          case "embed":
            nfcContents.text = "Einbettung"
            nfcContents.icon = "aspect_ratio"
            break;
          case "menu":
            nfcContents.text = "Menü"
            nfcContents.icon = "menu_book"
            break;
          default:
            console.error("No such nfc content")
        }
        nfcContents.key = Math.random()
        this.$store.commit("content/addContent", nfcContents)
      }
    },
    /**
     * Create link as content
     */
    createLink() {
      this.contentToEdit = {
        text: 'Link', icon: 'link', mime_type: 'link', content: {
          url: null
        }, order: 0, key: new Date().getTime().toString()
      }
      this.editLinkDialog = true
    },
    /**
     * check if user scroll down or up
     * Is needed to hide app bar. Otherwise user can't scroll up
     */
    handleScroll(newIndex) {
      const newPos = newIndex.originalEvent.clientY
      const oldPos = this.lastScrollPosition
      if (oldPos > newPos) {
        this.$store.commit("helper/setShowAppBar", false)
      }
    },
    startDrag(event) {
      this.drag = true
      this.lastScrollPosition = event.originalEvent.clientY
    },
    endDrag() {
      this.drag = false
      this.$store.commit("helper/setShowAppBar", true)
    },
    /**
     * show success alert
     * @param message
     */
    showSuccessAlert(message) {
      this.alertText = message
      this.alertType = "success"
      this.showAlert = true;
    },
    /**
     * show error alert
     * @param message
     */
    showErrorAlert(message) {
      this.alertText = message
      this.alertType = "error"
      this.showAlert = true;
    },
  }
}
</script>

<style scoped>
.ghost div {
  opacity: 0.7;
  background-color: #cfe6cf !important;
}

.center-view {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  height: 50px;
  width: auto;
}

/*.small-btn-placeholder {*/
/*  height: 28px;*/
/*  min-width: 50px;*/
/*  padding: 0 12.4444444444px;*/
/*}*/

.distances {
  padding-right: 8px;
  padding-top: 16px;
  padding-left: 8px;
  margin-bottom: 16px;
}

</style>