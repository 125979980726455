<template>
  <v-dialog
      v-model="modelHelper"
      max-width="600px"
      persistent
  >
    <v-card>
      <validation-observer v-slot="{invalid}">
        <form>
          <v-card-title>
            <span class="text-h5">Nutzer bearbeiten</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="Name"
                    rules="required"
                >
                  <v-text-field
                      v-model="user.name"
                      :error-messages="errors">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Name
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="E-Mail"
                    type="email"
                    rules="required|email"
                >
                  <v-text-field
                      v-model="user.email"
                      :error-messages="errors">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>E-Mail
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <user-fields v-model="user"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close()"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                :disabled="invalid"
                :loading="loading"
                @click="edit()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>

import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import UserFields from "../field/UserFields";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} ist erforderlich',
})

extend('email', {
  ...email,
  message: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
})

export default {
  components: {UserFields, ValidationObserver, ValidationProvider},
  props: {
    value: {
      required: true
    },
    userToEdit: {
      required: true
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      user: {
        id: null,
        name: null,
        email: null,
        street: null,
        postalcode: null,
        city: null,
        country: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.user)
    },
    close() {
      this.$emit('input', false)
    },
  },
  watch: {
    value() {
      if (!this.userToEdit) {
        return
      }
      this.user.id = this.userToEdit.id
      this.user.name = this.userToEdit.name
      this.user.email = this.userToEdit.email
      this.user.street = this.userToEdit.street
      this.user.postalcode = this.userToEdit.postalcode
      this.user.city = this.userToEdit.city
      this.user.country = this.userToEdit.country
    }
  }
}
</script>

<style scoped>

</style>