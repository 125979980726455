<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent max-width="700" :retain-focus="false">
    <validation-observer v-slot="{invalid}">
      <form>
        <edit-content
            v-model="quiz.menu_title"
            :content-text="quiz.text"
            @close="$emit('input', false)"
            :disabled="invalid"
            @save="save">
          <trumbowyg-editor v-if="modelHelper" v-model="quiz.content.question"/>

          <div class="my-2">Hier bitte eine Antwortmöglichkeit eintragen:</div>
          <trumbowyg-editor id="trumbow" v-model="answer" allow-images :undo-button="false"/>
            <v-btn class="my-4" :disabled="answer === ''" large outlined color="primary" @click="addAnswer()">
              {{ numberOfAnswers }}. Antwortmöglichkeit <br>
              Hinzufügen
              <v-icon>add</v-icon>
            </v-btn>


          <validation-provider
              v-slot="{errors}"
              rules="required">
            <v-select :items="answers" v-model="correctAnswer" class="mt-4"
                      no-data-text="Es sind noch keine Antworten vorhanden"
                      :error-messages="errors">
              <template #label>
                <span class="red--text"><strong>* </strong></span>Korrekte Antwort
              </template>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                <div class="answers-no-padding" v-html="data.item"/>
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <div class="answers-no-padding" v-html="data.item"/>
              </template>
            </v-select>
          </validation-provider>
          <v-radio-group readonly v-model="correctAnswer">
            <div v-for="(answer, idx) of quiz.content.answers" :key="answer.answer_text + idx">
              <v-row align="center">
                <v-col cols="auto">
                  <v-btn icon @click="removeAnswer(answer.answer_text)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-radio readonly :label="answer.answer_text" :value="answer.answer_text" on-icon="done">
                    <template v-slot:label>
                      <div class="answers-no-padding" v-html="answer.answer_text"/>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
        </edit-content>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import {extend, setInteractionMode, ValidationProvider, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import TrumbowygEditor from "../component/TrumbowygEditor";

setInteractionMode('aggressive')

extend('required', {
  ...required,
  message: 'Bitte gebe eine korrekte Antwort an',
})


export default {
  components: {
    TrumbowygEditor,
    EditContent,
    ValidationProvider, ValidationObserver
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      answer: "",
      numberOfAnswers: 1,
      correctAnswer: null,
      quiz: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          question: null,
          answers: [],
        },
        order: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    answers() {
      return this.quiz.content.answers.map(answer => answer.answer_text)
    }
  },
  methods: {
    addAnswer() {
      this.quiz.content.answers.push({answer_text: this.answer})
      this.numberOfAnswers++
      this.answer = "";
    },
    removeAnswer(answer) {
      const index = this.quiz.content.answers.findIndex(element => {
        return element.answer_text === answer;
      });
      if (index < 0) {
        return;
      }
      this.quiz.content.answers.splice(index, 1)
      this.numberOfAnswers--
    },
    save() {
      for (let answer of this.quiz.content.answers) {
        answer.is_correct = answer.answer_text === this.correctAnswer;
      }
      this.$emit('save', this.quiz)
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.quiz = JSON.parse(JSON.stringify(this.content))
        this.quiz.css_id = this.quiz.css_id === null ? "quiz-" + Date.now() : this.quiz.css_id;
        this.numberOfAnswers = this.quiz.content.answers.length + 1
        const correctAns = this.quiz.content.answers.filter(answer => answer.is_correct)
        if (correctAns[0]) {
          this.correctAnswer = correctAns[0].answer_text
        }
        if (this.quiz.content.question === null)
          this.quiz.content.question = "Stelle hier deine Frage...";
      }
    }
  }
}
</script>

<style scoped>
>>> .trumbowyg-box, >>> .trumbowyg-editor {
  min-height: 150px;
}

.answers-no-padding >>> p {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-block-end: 0 !important;
}

</style>