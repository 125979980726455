<template>
  <v-card color="primary" class="px-2" min-width="200px">
    <v-row no-gutters align="center">
      <v-col class="mr-1">
        {{ text }}
      </v-col>
      <v-col v-if="!isLink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="$emit('copy')">
              <v-icon>file_copy</v-icon>
            </v-btn>
          </template>
          <span>Duplizieren</span>
        </v-tooltip>
      </v-col>
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" tool icon @click="$emit('edit')">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
      </v-col>
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="$emit('delete')">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Entfernen</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="!isLink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="$emit('add')">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Spalte hinzufügen</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String
    },
    isLink: {
      required: true,
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>