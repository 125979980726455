<template>
  <v-dialog
      v-model="modelHelper"
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Nutzer löschen</span>
      </v-card-title>
      <v-card-text>
        <p>Möchtest du den Nutzer
          <strong>{{ userToDelete !== null ? userToDelete.name : null }}</strong>
          wirklick löschen?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            :disabled="loading"
            @click="$emit('input', false)"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="error"
            text
            :loading="loading"
            @click="deleteBtn()"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    userToDelete: {
      required: true,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    deleteBtn() {
      this.$emit("delete")
    }
  }
}
</script>

<style scoped>

</style>