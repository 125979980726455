<template>
  <v-container class="mx-auto max-width-820">
    <template v-if="loadingData">
      <div class="text-center ma-16">
        <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </div>
    </template>
    <template v-else-if="!isNfcSlugValid">
      <h3 class="text-center ma-8">Uuuuppps.. Hier ist etwas schief gelaufen</h3>
    </template>
    <template v-else>
      <v-row v-for="content in contents"
             :key="content.index"
             no-gutters>
        <v-col v-for="item in content.items"
               :key="item.css_id"
               :cols="item.col_width">
          <customer-content :id="item.css_id" :nfc-content="item"
                            :accent-color="nfcTag.accent_color !== null ? nfcTag.accent_color : $vuetify.theme.themes.light.primary"/>
        </v-col>
      </v-row>
    </template>
    <scroll-to-top :color="nfcTag.accent_color"/>
  </v-container>
</template>

<script>
import {NetworkTag} from "../../helper/network/tag/NetworkTag";
import CustomerContent from "./component/CustomerContent";
import ScrollToTop from "../../components/ScrollToTop";

export default {
  components: {ScrollToTop, CustomerContent},
  data() {
    return {
      nfcTag: {
        name: null,
        logo: null,
        alignment_logo: null,
        background_color: null,
        bar_color: null,
        accent_color: null,
        description: null,
      },
      loadingData: false,
      isNfcSlugValid: true
    }
  },
  created() {
    this.fetchNfcTag()
    this.$store.commit("helper/setShowNavBar", false)
  },
  computed: {
    contents() {
      return this.$store.state.content.nfcContents
    }
  },
  /**
   * Get NFC tag from slug in the url
   */
  methods: {
    async fetchNfcTag() {
      this.loadingData = true;
      this.$store.commit("helper/setBarColor", "#f5f5f5")
      try {
        const {data} = await NetworkTag.getTagFromSlug(this.$route.query.slug)
        this.nfcTag = data.data
        this.$store.commit("content/setLogo", this.nfcTag.logo)
        this.$store.commit("helper/setBackgroundColor", this.nfcTag.background_color)
        this.$store.commit("helper/setBarColor", this.nfcTag.bar_color)
        this.$store.commit("content/setContents", this.nfcTag.nfc_contents)
        this.$store.commit("helper/setAlignmentLogo", this.nfcTag.alignment_logo)
        this.isNfcSlugValid = true
        if (this.nfcTag.css !== null) this.addCss(this.nfcTag.css)
      } catch (e) {
        console.error(e)
        this.isNfcSlugValid = false
      } finally {
        this.loadingData = false
      }
    },
    addCss(css) {
      const head = document.getElementsByTagName('head')[0];
      let s = document.createElement('style');
      s.setAttribute('type', 'text/css');
      s.appendChild(document.createTextNode(css));
      head.appendChild(s);
    }
  }
}
</script>

<style scoped>
.max-width-820 {
  max-width: 820px;
}

</style>