<template>
  <div>
    <data-table
        title="Nutzer"
        :headers="computedHeaders"
        :items="users"
        :loading="loading"
        :total="totalUsers"
        v-model="options"
        loading-text="Lade Nutzer..."
        action-button-text="Neuen Nutzer anlegen"
        @clickBtn="openCreateDialog()">
      <template v-slot:actions="{item}">
        <v-icon
            class="mr-2"
            @click="openEditDialog(item)">
          edit
        </v-icon>
        <v-icon
            @click="openDeleteDialog(item)">
          delete
        </v-icon>
      </template>
    </data-table>
    <delete-user-dialog v-model="deleteDialog"
                        :user-to-delete="currentSelectedUser"
                        @delete="deleteUser"
                        :loading="loadingDeleteDialog"/>
    <edit-user-dialog v-model="editDialog"
                      :user-to-edit="currentSelectedUser"
                      @edit="editUser"
                      :loading="loadingEditDialog"/>
    <create-user-dialog v-if="createDialog" v-model="createDialog"
                        @create="createUser"
                        :loading="loadingCreateDialog"/>
    <notification v-model="showAlert" :type="alertType" :text="alertText"/>
  </div>
</template>

<script>
import {NetworkUser} from "../../../helper/network/user/NetworkUser";
import DataTable from "../../../components/table/DataTable";
import DeleteUserDialog from "../../../components/dialog/DeleteUserDialog";
import CreateUserDialog from "../../../components/dialog/CreateUserDialog";
import EditUserDialog from "../../../components/dialog/EditUserDialog";
import Notification from "../../../components/Notification";

export default {
  components: {Notification, EditUserDialog, CreateUserDialog, DeleteUserDialog, DataTable},
  data() {
    return {
      headers: [
        {text: 'Name', value: 'name', mobile: true},
        {text: 'E-mail', value: 'email', mobile: true},
        {text: 'Straße', value: 'street', mobile: false},
        {text: 'PLZ', value: 'postalcode', mobile: false},
        {text: 'Ort', value: 'city', mobile: false},
        {text: 'Land', value: 'country', mobile: false},
        {text: 'Aktionen', value: 'actions', sortable: false, mobile: true}
      ],
      users: [],
      totalUsers: 0,
      loading: false,
      deleteDialog: false,
      editDialog: false,
      options: {},
      createDialog: false,
      editingUser: false,
      loadingEditDialog: false,
      loadingDeleteDialog: false,
      loadingCreateDialog: false,
      currentSelectedUser: null,
      showAlert: false,
      alertType: "success",
      alertText: "",
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchUsers()
      },
      deep: true,
    },
  },
  computed: {
    /**
     * hide some headers if table switch to mobile view
     */
    computedHeaders() {
      if (this.$vuetify.breakpoint.xs) {
        return this.headers.filter(head => head.mobile)
      }
      return this.headers
    }
  },
  methods: {
    /**
     * Get all Users
     * @returns {Promise<void>}
     */
    async fetchUsers() {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const {data} = await NetworkUser.getUsers(page, itemsPerPage, sortBy, sortDesc)
        this.totalUsers = data.meta.total
        this.users = data.data
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    openDeleteDialog(user) {
      this.currentSelectedUser = user;
      this.deleteDialog = true;
    },
    openEditDialog(user) {
      this.editingUser = user !== null;
      this.currentSelectedUser = user;
      this.editDialog = true;
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    /**
     * delete User
     * @returns {Promise<void>}
     */
    async deleteUser() {
      this.loadingDeleteDialog = true;
      try {
        await NetworkUser.deleteUser(this.currentSelectedUser.id)
        await this.fetchUsers()
        this.showSuccessAlert("Nutzer erfolgreich gelöscht")
      } catch (err) {
        console.error(err)
        this.showErrorAlert("Nutzer konnte nicht gelöscht werden")
      }
      this.deleteDialog = false
      this.currentSelectedUser = null
      this.loadingDeleteDialog = false;
    },
    async editUser(user) {
      this.loadingEditDialog = true;
      try {
        await NetworkUser.updateUser(user)
        await this.fetchUsers()
        this.showSuccessAlert("Nutzer erfolgreich bearbeitet")
      } catch (err) {
        console.error(err)
        this.showErrorAlert("Nutzer konnte nicht bearbeitet werden")
      }
      this.editDialog = false
      this.currentSelectedUser = null
      this.loadingEditDialog = false;
    },
    async createUser(user) {
      this.loadingCreateDialog = true;
      try {
        await NetworkUser.createUser(user)
        await this.fetchUsers()
        this.showSuccessAlert("Nutzer erfolgreich angelegt")
      } catch (err) {
        console.error(err)
        this.showErrorAlert("Nutzer konnte nicht angelegt werden")
      }
      this.createDialog = false
      this.loadingCreateDialog = false;
    },
    /**
     * show success alert
     * @param message
     */
    showSuccessAlert(message) {
      this.alertText = message
      this.alertType = "success"
      this.showAlert = true;
    },
    /**
     * show error alert
     * @param message
     */
    showErrorAlert(message) {
      this.alertText = message
      this.alertType = "error"
      this.showAlert = true;
    }
  },
}
</script>

<style scoped>

</style>