<template>
  <v-container>
    <v-card>
      <validation-observer v-slot="{invalid}">
        <form>
          <v-card-title>
            <h2 class="text-uppercase">Dein Profil
              <v-btn v-if="!edit" icon @click="edit = true">
                <v-icon color="primary">edit</v-icon>
              </v-btn>
            </h2>
          </v-card-title>
          <v-card-text>
            <template v-if="!edit">
              <h2 class="mb-2">{{ user.name }}</h2>
              <v-row>
                <v-col cols="auto">
                  <div>E-Mail:</div>
                  <div v-if="user.street || user.postalcode || user.city || user.country">
                    Adresse:
                  </div>
                </v-col>
                <v-col>
                  <div>{{ user.email }}</div>
                  <div>{{ user.street }}</div>
                  <div>{{ user.postalcode }} {{ user.city }}</div>
                  <div>{{ user.country }}</div>
                </v-col>
              </v-row>
            </template>
            <v-row v-else>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="Name"
                    rules="required"
                >
                  <v-text-field
                      readonly
                      v-model="user.name"
                      :error-messages="errors"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Name
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="E-Mail"
                    rules="required|email"
                >
                  <v-text-field
                      v-model="user.email"
                      type="email"
                      :error-messages="errors">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>E-Mail
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <user-fields v-model="user"/>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    rules="confirmed:password"
                    mode="aggressive"
                    v-slot="{errors}"
                    name="Password">
                  <v-text-field v-model="user.password"
                                :error-messages="errors"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                label="Neues Passwort">
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    vid="password"
                    mode="aggressive"
                    v-slot="{errors}"
                    name="Password confirmation">
                  <v-text-field v-model="user.password_confirmation"
                                :error-messages="errors"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                label="Neues Passwort wiederholen">
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="edit">
            <v-spacer></v-spacer>
            <v-btn
                :disabled="loadingDialog"
                @click="close()"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="primary"
                :disabled="invalid"
                :loading="loadingDialog"
                @click="updateProfile()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
    <notification v-model="showAlert" :type="alertType" :text="alertText"/>
  </v-container>
</template>

<script>
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import UserFields from "../components/field/UserFields";
import {confirmed, email, required} from "vee-validate/dist/rules";
import {NetworkUser} from "../helper/network/user/NetworkUser";
import {NetworkAuth} from "../helper/network/auth/NetworkAuth";
import Notification from "../components/Notification";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} ist erforderlich',
})

extend('confirmed', {
  ...confirmed,
  message: 'Passwörter stimmen nicht überein',
})

extend('email', {
  ...email,
  message: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
})

export default {
  components: {Notification, UserFields, ValidationObserver, ValidationProvider},
  data() {
    return {
      user: null,
      edit: false,
      showAlert: false,
      alertType: null,
      alertText: "",
      loadingDialog: false,
      showPassword: false,
    }
  },
  created() {
    this.user = this.$store.state.auth.user
  },
  methods: {
    close() {
      this.edit = false
      this.user = this.$store.state.auth.user
    },
    async updateProfile() {
      this.loadingDialog = true;
      try {
        await NetworkUser.updateUser(this.user)
        const userData = await NetworkAuth.getUserData()
        const user = userData.data.data
        this.$store.commit('auth/setUser', user)
        this.showSuccessAlert("Profil erfolgreich bearbeitet")
      } catch (err) {
        console.error(err)
        this.showErrorAlert("Profil konnte nicht bearbeitet werden")
      }
      this.loadingDialog = false
      this.edit = false
    },
    /**
     * show success alert
     * @param message
     */
    showSuccessAlert(message) {
      this.alertText = message
      this.alertType = "success"
      this.showAlert = true;
    },
    /**
     * show error alert
     * @param message
     */
    showErrorAlert(message) {
      this.alertText = message
      this.alertType = "error"
      this.showAlert = true;
    }
  }
}
</script>

<style scoped>

</style>