import axios from "axios";

export class NetworkUser {
    static getUsers(page, itemsPerPage, sortBy, sortDesc, config) {
        return axios.get('api/user?page='+ page + '&itemsPerPage=' + itemsPerPage + '&sortBy=' + sortDesc + '&sortDesc=' + itemsPerPage, config)
    }

    static deleteUser(userId, config) {
        return axios.delete(`api/user/${userId}`, config)
    }

    static updateUser(user, config) {
        return axios.put(`api/user/${user.id}`, user, config)
    }

    static createUser(user, config) {
        return axios.post('api/user', user, config)
    }
}
