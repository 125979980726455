<template>
  <v-dialog v-model="modelHelper" v-if="content" persistent :retain-focus="false">
    <edit-content
        v-model="text.menu_title"
        :content-text="text.text"
        @close="$emit('input', false)"
        @save="$emit('save', text)"
        :disabled="!text.content.text_content">
      <trumbowyg-editor v-if="modelHelper" v-model="text.content.text_content"/>
    </edit-content>
  </v-dialog>
</template>

<script>
import EditContent from "../component/EditContent";
import TrumbowygEditor from "../component/TrumbowygEditor";


export default {
  components: {
    TrumbowygEditor,
    EditContent,
  },
  props: {
    value: {
      required: true
    },
    content: {
      required: true
    },
  },
  data() {
    return {
      commands: [],
      text: {
        text: null,
        icon: null,
        mime_type: null,
        menu_title: null,
        css_id: null,
        col: null,
        content: {
          text_content: null
        },
        order: null,
        key: null,
      },
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.text = JSON.parse(JSON.stringify(this.content))
        this.text.css_id = this.text.css_id === null ? "text-" + Date.now() : this.text.css_id;
      }
    }
  },
}
</script>

<style scoped>

</style>