<template>
  <v-dialog
      v-model="modelHelper"
      max-width="600px"
      persistent
  >
    <v-card>
      <validation-observer v-slot="{invalid}">
        <form>
          <v-card-title>
            <span class="text-h5">Nutzer anlegen</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="Name"
                    rules="required"
                >
                  <v-text-field
                      v-model="user.name"
                      :error-messages="errors">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Name
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                    v-slot="{errors}"
                    name="E-mail"
                    rules="required|email"
                >
                  <v-text-field
                      v-model="user.email"
                      type="email"
                      :error-messages="errors">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>E-Mail
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <user-fields v-model="user"/>
              </v-col>
              <template>
                <v-col cols="12">
                  <validation-provider
                      mode="aggressive"
                      v-slot="{errors}"
                      name="Passwort"
                      rules="required|confirmed:password">
                    <v-text-field v-model="user.password"
                                  :error-messages="errors"
                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPassword ? 'text' : 'password'"
                                  @click:append="showPassword = !showPassword"
                                  required>
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Passwort
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                      mode="aggressive"
                      v-slot="{errors}"
                      name="Passwort"
                      vid="password"
                      rules="required">
                    <v-text-field v-model="user.password_confirmation"
                                  :error-messages="errors"
                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPassword ? 'text' : 'password'"
                                  @click:append="showPassword = !showPassword"
                                  required>
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Passwort bestätigen
                    </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close()"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                :disabled="invalid"
                :loading="loading"
                @click="create()"
            >
              Anlegen
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>

import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import {confirmed, email, required} from "vee-validate/dist/rules";
import UserFields from "../field/UserFields";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} ist erforderlich',
})

extend('confirmed', {
  ...confirmed,
  message: 'Passwörter stimmen nicht überein',
})

extend('email', {
  ...email,
  message: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
})

export default {
  components: {UserFields, ValidationObserver, ValidationProvider},
  props: {
    value: {
      required: true
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      user: {
        id: null,
        name: null,
        email: null,
        street: null,
        postalcode: null,
        city: null,
        country: null,
        password: null,
        password_confirmation: null
      },
      showPassword: false,
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    create() {
      this.$emit('create', this.user)
    },
    close() {
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped>

</style>